import React, { useState, useEffect } from "react";
import AlertDialog from "../components/AlertDialog";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import { Typography, TextField, Button, Grid, Card } from "@mui/material";
import { api } from "common";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CircularLoading from "components/CircularLoading";
import { colors } from "components/Theme/WebTheme";
import { MAIN_COLOR, SECONDORY_COLOR } from "../common/sharedFunctions";
import { makeStyles } from "@mui/styles";
import GoogleMapsAutoComplete from '../components/GoogleMapsAutoComplete';
const useStyles = makeStyles((theme) => ({
  textField: {
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  selectField: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR,
    },
  },
  rootRtl_3: {
    "& label": {
      right: 17,
      left: "auto",
      paddingRight: 12
    },
    "& legend": {
      textAlign: "right",
      marginRight: 20
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl: {
    "& label": {
      right: 20,
      left: "auto",
      paddingRight: 20
    },
    "& legend": {
      textAlign: "right",
      marginRight: 15
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl_1: {
    "& label": {
      right: 0,
      left: "auto",
      paddingRight: 20
    },
    "& legend": {
      textAlign: "right",
      marginRight: 30
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl_2: {
    "& label": {
      right: 17,
      left: "auto",
      paddingRight: 12
    },
    "& legend": {
      textAlign: "right",
      marginRight: 25
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl_4: {
    "& label": {
      right: 17,
      left: "auto",
      paddingRight: 12
    },
    "& legend": {
      textAlign: "right",
      marginRight: 15
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  selectField_rtl: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR,
    },
    "& label": {
      right: 0,
      left: "auto",
    },
    "& legend": {
      textAlign: "right",
      marginRight: 35,
    },
  },

  right: {
    textAlign: "right",
    right: 0,
    left: "auto",
    paddingRight: 40,
  },
}));

const AddDeliveryLocation = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector((state) => state.settingsdata.settings);
  const servicedata = useSelector(state => state.servicedata);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loding, setLoding] = useState(false);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const { editDeliveryLocations } = api;
  const classes = useStyles();
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email:"",
    mobile: "",
    companyName: "",
    country:""
  });
  const auth = useSelector((state) => state.auth);
  const [services,setServices] = useState();
  const[serviceAddress,setServiceAddress] = useState(null);
  const [countries, setCountries] = useState();
  const countrylistdata = useSelector((state) => state.countrylistdata);

  useEffect(() => {
    if (servicedata && servicedata.service) {
      // const activeServices = servicedata.service.filter((item)=>{
      //   return(
      //     item.active === true
      //   )
      // }) 
      let service = servicedata.service
      if(service && service.length > 0 ){
        let arr =[];
        for(let i = 0; i < service.length ; i++){
          if(service[i].active && service[i].service_name !== "Select Your Choice Destination"){
            arr.push(service[i])
          }
        }
        setServices(arr);
      }
      
    }
  }, [servicedata]);

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  const handleChangeServiceType = (e) => {
    setData({ ...data, service_type: e.target.value });
  };

 // useEffect(() => {
  //  if (countrylistdata && countrylistdata.countries && countrylistdata.countries.length > 0) {
 //     const countries = countrylistdata.countries.sort((a, b) => a.pos - b.pos);
  //    let arr = [];
  //    for (let i = 0; i < countries.length; i++) {
   //     if( auth.profile.usertype === 'admin' && auth.profile.country && auth.profile.country.length > 0 && auth.profile.country_code === countries[i].country_code){
  //        setData({ ...data, country: countries[i].country, country_code: countries[i].country_code});
   //     }
   //     arr.push({
   //       label: countries[i].country,
   //       code: countries[i].country_code,
   //       phone: countries[i].phone_code,
    //    });
   //   }
 //     setCountries(arr);
 //   }
//}, [countrylistdata, countrylistdata.countries, auth]);*/}

useEffect(() => {
  if (countrylistdata && countrylistdata.countries && countrylistdata.countries.length > 0) {
    const countries = countrylistdata.countries.sort((a, b) => a.pos - b.pos);
    let arr = [];
    for (let i = 0; i < countries.length; i++) {
      if (auth.profile.usertype === 'admin' && auth.profile.country && auth.profile.country.length > 0 && auth.profile.country_code === countries[i].country_code){
        setData(d => ({ ...d, country: countries[i].country, country_code: countries[i].country_code }));
      }
      arr.push({
        label: countries[i].country,
        code: countries[i].country_code,
        phone: countries[i].phone_code,
      });
    }
    setCountries(arr);
  }
}, [countrylistdata, countrylistdata.countries, auth]);


  const handleCountryChange = (event) => {
    setData({ ...data, country: event.target.value, country_code: event.target.value.code });
  };

  const handelSubmit = () => {
    if (
      !(
        data &&
        data.firstName &&
        data.lastName &&
        data.mobile &&
        data.email &&
        data.companyName &&
        data.service_type &&
        serviceAddress &&
        data.country
      )
    ) {
      if (!(data && data.firstName, data.lastName)) {
        setCommonAlert({ open: true, msg: t("proper_input_name") });
      } else if (!data.mobile) {
        setCommonAlert({ open: true, msg: t("mobile_no_blank_error") });
      } else if (!data.email) {
        setCommonAlert({ open: true, msg: t("proper_email") });
      } else if (!data.companyName) {
        setCommonAlert({ open: true, msg: t("company_required") });
      } else if (data.country === "") {
        setCommonAlert({ open: true, msg: t("country_blank_error") });
      } else if (!data.service_type) {
        setCommonAlert({ open: true, msg: t("service_type_required") });
      } else if (!serviceAddress) {
        setCommonAlert({ open: true, msg: t("service_address_required") });
      } 
    } else {
      settings.AllowCriticalEditsAdmin
        ? 
        new Promise((resolve, reject) => {
          setLoding(true);
          setTimeout(() => {
            if (data && data.firstName && data.lastName) {
              data["createdAt"] = new Date().getTime();
              const deliveryLocationData = {...data, companyAddress:{lat:serviceAddress.coords.lat, lng:serviceAddress.coords.lng, dec:serviceAddress.description}, country: auth && auth.profile.country_code ? data.country : data.country.label}
              dispatch(editDeliveryLocations(deliveryLocationData, "Add"));
              resolve();
              setLoding(false);
              setData({
                firstName: "",
                lastName: "",
                email:"",
                mobile: "",
                companyName: "",
              });
              setServiceAddress(null);
              navigate("/location");
            } else {
              setCommonAlert({ open: true, msg: t("proper_input_name") });
              reject(new Error("Enter proper name"));
              setLoding(false);
            }
          }, 600);
        })
        : new Promise((resolve) => {
          setTimeout(() => {
            resolve();
            setCommonAlert({ open: true, msg: t("demo_mode") });
          }, 600);
        });
    }
  };
  return loding ? (
    <CircularLoading />
  ) : (
    <div>
      <Card
        style={{
          borderRadius: "19px",
          backgroundColor: "#fff",
          minHeight: 100,
          maxWidth: "75vw",
          marginTop: 20,
          marginBottom: 20,
          padding: 25,
          alignItems: "center",
          justifyContent: "center",
          boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
        }}
      >
        <Typography
          variant="h5"
          style={{
            marginTop: -15,
            textAlign: isRTL === "rtl" ? "right" : "left",
          }}
        >
          {t("add_dropoff_location")}
        </Typography>
        {/* <div
          dir={isRTL === "rtl" ? "rtl" : "ltr"}
          >
          <Button
            variant="text"
            onClick={() => {
              navigate("/location");
            }}
          >
            <Typography
              style={{
                marginBottom: 10,
                textAlign: isRTL === "rtl" ? "right" : "left",
                fontWeight: "bold",
                color: MAIN_COLOR,
              }}
            >
              {`<<- ${t("go_back")}`}
            </Typography>
          </Button>
        </div> */}
        <Grid
          container
          spacing={2}
          style={{marginTop: 10}}
          sx={{
            gridTemplateColumns: "50%",
            direction:isRTL==='rtl'?'rtl':'ltr'
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("firstname")}
              id="firstName"
              value={data.firstName}
              fullWidth
              onChange={handleInputChange}
              className={isRTL === "rtl" ? classes.rootRtl : classes.textField}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("lastname")}
              id="lastName"
              value={data.lastName}
              fullWidth
              onChange={handleInputChange}
              className={isRTL === "rtl" ? classes.rootRtl_3 : classes.textField}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("mobile")}
              id="mobile"
              value={data.mobile || ""}
              fullWidth
              onChange={handleInputChange}
              className={isRTL === "rtl" ? classes.rootRtl_3: classes.textField}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("email")}
              id="email"
              value={data.email}
              fullWidth
              onChange={handleInputChange}
              className={isRTL === "rtl" ? classes.rootRtl_3 : classes.textField}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("CompanyName")}
              id="companyName"
              value={data.companyName}
              fullWidth
              onChange={handleInputChange}
              className={isRTL === "rtl" ? classes.rootRtl_4: classes.textField}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <FormControl
              fullWidth
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
            >
              <InputLabel
                id="demo-simple-select-label"
                className={isRTL === "rtl" ? classes.right : ""}
                sx={{ "&.Mui-focused": { color: MAIN_COLOR } }}
              >
                {t("service_type")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="service_type"
                value={data.service_type || ""}
                label={t("service_type")}
                onChange={handleChangeServiceType}
                className={
                  isRTL === "rtl"
                    ? classes.selectField_rtl
                    : classes.selectField
                }
              >
              {services?.map((item,idx)=>
                <MenuItem
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                  value={item.service_name}
                  key={idx}
                >
                  {item.service_name}
                </MenuItem>
              )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <GoogleMapsAutoComplete
                variant={"outlined"}
                placeholder={t('service_address')}
                value={serviceAddress}
                className={classes.items}
                onChange={
                  (value) => {
                    setServiceAddress(value);
                  }
                }
              />
          </Grid>
          {auth && auth.profile.usertype === 'admin' && auth.profile.country_code === undefined ?
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            { countries && countries.length > 0 ?
              <FormControl fullWidth style={{ direction: isRTL === "rtl" ? "rtl" : 'ltr' }}>
              <InputLabel
                id="demo-simple-select-label"
                className={isRTL === "rtl" ? classes.right : ""}
                sx={{ "&.Mui-focused": { color: colors.MAIN_COLOR } }}
              >
                {t("select_country")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.country || ""}
                label={t("select_country")}
                onChange={handleCountryChange}
                className={isRTL === "rtl" ? classes.selectField_rtl : classes.selectField}
              >
              {countries.map((item, idx)=>(
                <MenuItem style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }} value={item} key = {idx}>{item.label}</MenuItem>
              ))}
              </Select>
            </FormControl>
              : null}
            </Grid>
          : null}
            
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <Button
              style={{
                borderRadius: "5px",
                backgroundColor: MAIN_COLOR,
                minHeight: 65,
                minWidth: "100%",
                textAlign:"center",
				boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
              }}
              onClick={handelSubmit}
              variant="contained"
            >
              <Typography
                style={{
                  color: colors.WHITE,
                  textAlign: "center",
                  fontSize: 16,
                }}
              >
                {t("submit")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Card>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
};

export default AddDeliveryLocation;