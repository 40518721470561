import React, { useState, useEffect, useRef } from 'react';
import { downloadCsv } from '../common/sharedFunctions';
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import { colors } from '../components/Theme/WebTheme';
import moment from 'moment/min/moment-with-locales';
import { SECONDORY_COLOR } from "../common/sharedFunctions";
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import {FormControl, Select, MenuItem} from "@mui/material";

export default function DeliveryLocations() {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir();
    const settings = useSelector(state => state.settingsdata.settings);
    const {
        editDeliveryLocations
    } = api;
    const [data, setData] = useState([]);
    const deliveryLocationData = useSelector(state => state.deliverylocationdata.deliverylocation);
    const dispatch = useDispatch();
    const loaded = useRef(false);
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);


    const countrylistdata = useSelector(state => state.countrylistdata);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(auth && auth.profile && auth.profile.country_code ? auth.profile.country_code : t('all'));
  
    useEffect(() => {
      if (countrylistdata.countries) {
        if (countrylistdata.countries) {
          setCountries(countrylistdata.countries);
        } else {
          setCountries([]);
        }
        if(auth.profile.usertype === 'admin' && auth.profile.country === undefined ){
          let country = countrylistdata.countries;
          for (let i = 0; i < country.length; i++) {
            if (country[i].pos === 0) {
              setSelectedCountry(country[i].country_code)
            }
          }
        }else{
          
          setSelectedCountry(auth.profile.country_code)
        }
      }
    }, [countrylistdata.countries, auth]);
    
    const handleCountrySelect = (event) => {
      setSelectedCountry(event.target.value);
    }

    useEffect(() => {
        if (deliveryLocationData) {
            setData(deliveryLocationData.filter((user) => ((auth.profile.usertype === 'admin' && auth.profile.country === undefined && (user.country_code === selectedCountry || selectedCountry === "All")) || ((auth.profile.usertype === 'admin' || auth.profile.usertype === 'fleetadmin') && auth.profile.country_code && auth.profile.country_code.length > 0 && user.country_code === auth.profile.country_code))));

            // setData(
            //     staticusers.filter(
            //       (user) =>
            //         user.usertype === "driver" &&
            //         ((user.fleetadmin === auth.profile.uid && auth.profile.usertype === "fleetadmin") ||
            //           (user.country === auth.profile.country && auth.profile.usertype === 'admin' && auth.profile.country && auth.profile.country.length > 0) ||  
            //           ( auth.profile.usertype === 'admin' && auth.profile.country === undefined ))
            //     )
            //   );

        } else {
            setData([]);
        }
        loaded.current = true;
    }, [deliveryLocationData, auth, selectedCountry]);

    const columns = [
        { title: t('createdAt'), field: 'createdAt', editable: 'never', defaultSort: 'desc', render: rowData => rowData.createdAt ? moment(rowData.createdAt).format('lll') : null },
        { title: t('CompanyName'), field: 'companyName',  },
        { title: t('company_address'), field: 'companyAddress.dec' },
        { title: t('first_name'), field: 'firstName',  },
        { title: t('last_name'), field: 'lastName',  },
        { title: t('email'), field: 'email',  },
        { title: t('mobile'), field: 'mobile',  },
        {title: t('country'), field: 'country', editable:'onAdd'},
        { title: t('service_type'), field: 'service_type',  },
    ];

    const [selectedRow, setSelectedRow] = useState(null);

    return (
        !loaded.current ? <CircularLoading /> :
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', direction: isRTL === 'rtl' ? 'rtl' : 'ltr', }}>
                {auth.profile.usertype === 'admin' && auth.profile.country === undefined ?
                <FormControl sx={{ m: 1, width: 240 }}>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedCountry}
                    onChange={handleCountrySelect}
                    style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', border: '1px solid #9E9E9E', borderRadius: 50, height: 45 }}
                >
                    <MenuItem value={t('all')} style={{direction:isRTL==='rtl'?'rtl':'ltr', width:'100%', justifyContent:'flex-start', paddingLeft:10}}>
                    {t('all')}
                    </MenuItem>
                    {countries.map((item) =>
                    <MenuItem dense={true} key={item.country} value={item.country_code} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', width: '100%', justifyContent: 'flex-start', paddingLeft: 10 }}>
                        {t(item.country)}
                    </MenuItem>
                    )}
                </Select>
                </FormControl>
                : null}
            </div>
            <MaterialTable
                title={t('service_type_title_nav')}
                columns={columns}
                style={{ 
                    direction: isRTL === "rtl" ? "rtl" : "ltr",
                    borderRadius: "8px",
                    boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
                    padding:5 
                }}
                data={data}
                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                options={{
                    pageSize: 10,
                    pageSizeOptions: [10, 15, 20],
                    exportCsv: (columns, data) => {
                        let hArray = [];
                        const headerRow = columns.map(col => {
                            if (typeof col.title === 'object') {
                                return col.title.props.text;
                            }
                            hArray.push(col.field);
                            return col.title;
                        });
                        const dataRows = data.map(({ tableData, ...row }) => {
                            row.createdAt = row.complainDate ? new Date(row.complainDate).toLocaleDateString() + ' ' + new Date(row.complainDate).toLocaleTimeString() : '';
                            row.processDate = row.processDate ? new Date(row.processDate).toLocaleDateString() + ' ' + new Date(row.processDate).toLocaleTimeString() : '';
                            let dArr = [];
                            for (let i = 0; i < hArray.length; i++) {
                                dArr.push(row[hArray[i]]);
                            }
                            return Object.values(dArr);
                        })
                        const { exportDelimiter } = ",";
                        const delimiter = exportDelimiter ? exportDelimiter : ",";
                        const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join("\n");
                        const csvFileName = 'Complain.csv';
                        downloadCsv(csvContent, csvFileName);
                    },
                    exportButton: {
                        csv: settings.AllowCriticalEditsAdmin,
                        pdf: false,
                    },
                    maxColumnSort: "all_columns",
                    rowStyle: rowData => ({
                        backgroundColor: (selectedRow === rowData.tableData.id) ? colors.THIRDCOLOR : colors.WHITE
                    }),
                    editable: {
                        backgroundColor: colors.WHITE,
                        fontSize: "0.8em",
                        fontWeight: 'bold ',
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                        color: colors.BLACK,
                    },
                    headerStyle: {
                        position: "sticky",
                        top: "0px",
                        fontSize: "0.8em",
                        fontWeight: "bold ",
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                        color: colors.BLACK,
                        backgroundColor: SECONDORY_COLOR,
                        textAlign: "center",
                        border: "1px solid rgba(224, 224, 224, 1)",
                        minWidth:"60px"
                    },
                    cellStyle: {
 
                        textAlign: "center",
                    },
                    actionsColumnIndex: -1,
                }}
                localization={{
                    body: {
                        addTooltip: (t('add')),
                        deleteTooltip: (t('delete')),
                        editTooltip: (t('edit')),
                        emptyDataSourceMessage: (
                            (t('blank_message'))
                        ),
                        editRow: {
                            deleteText: (t('delete_message')),
                            cancelTooltip: (t('cancel')),
                            saveTooltip: (t('save'))
                        },
                    },
                    toolbar: {
                        searchPlaceholder: (t('search')),
                        exportTitle: (t('export')),
                    },
                    header: {
                        actions: (t('actions'))
                    },
                    pagination: {
                        labelDisplayedRows: ('{from}-{to} ' + (t('of')) + ' {count}'),
                        firstTooltip: (t('first_page_tooltip')),
                        previousTooltip: (t('previous_page_tooltip')),
                        nextTooltip: (t('next_page_tooltip')),
                        lastTooltip: (t('last_page_tooltip'))
                    },
                }}
                editable={{
                    onRowDelete: (oldData) =>
                    settings.AllowCriticalEditsAdmin
                    ? new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                            dispatch(editDeliveryLocations(oldData, "Delete"));
                        }, 600);
                     })
                    : new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                            alert(t("demo_mode"));
                        }, 600);
                        }),
                }}
                actions={[    
                
                (rowData) => ({
                    tooltip: t("edit"),
                        icon: () => (
                        <div
                            style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            marginRight:0
                            }}
                        >
                            <EditIcon />  
                        </div>
                        ),
                        onClick: (event, rowData) =>{
                        navigate(`/location/locationupdate/${rowData.id}`)
                    }
                }),
            ]}/> 
        </>
    
    );
}