import {
    FETCH_DELIVERY_LOCATION,
    FETCH_DELIVERY_LOCATION_SUCCESS,
    FETCH_DELIVERY_LOCATION_FAILED,
    EDIT_DELIVERY_LOCATION
  } from "../store/types";

  import {firebase} from "../config/configureFirebase";
  import {onValue, push, set, remove} from "firebase/database";

  export const fetchDeliveryLocations = () => (dispatch) => {

    const {
      deliverylocationRef
    } = firebase;

    dispatch({
      type: FETCH_DELIVERY_LOCATION,
      payload: null
    });
    onValue(deliverylocationRef, snapshot => {
      if (snapshot.val()) {
        const data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          return data[i]
        });
        dispatch({
          type: FETCH_DELIVERY_LOCATION_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_DELIVERY_LOCATION_FAILED,
          payload: "No delivery location available."
        });
      }
    });
  };


  export const editDeliveryLocations = (deliveryLocations, method) => (dispatch)=>{

    const {deliverylocationEditRef,deliverylocationRef} = firebase;
    dispatch({
        type:EDIT_DELIVERY_LOCATION,
        payload:{method, deliveryLocations}
    });
    if(method === "Add"){
        push(deliverylocationRef, deliveryLocations);
    }else if (method === "Delete"){
        remove(deliverylocationEditRef(deliveryLocations.id));
    }else{
        set(deliverylocationEditRef(deliveryLocations.id), deliveryLocations);
    }
}