import React, { useState, useEffect, useRef } from 'react';
import { downloadCsv } from '../common/sharedFunctions';
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import { colors } from '../components/Theme/WebTheme';
import moment from 'moment/min/moment-with-locales';
import { SECONDORY_COLOR } from "../common/sharedFunctions";
import {FormControl, Select, MenuItem} from "@mui/material";

export default function Complain() {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir();
    const settings = useSelector(state => state.settingsdata.settings);
    const {
        fetchUsersOnce,
        editComplain
    } = api;
    const [data, setData] = useState([]);
    const complaindata = useSelector(state => state.complaindata.list);
    const dispatch = useDispatch();
    const loaded = useRef(false);

    const auth = useSelector(state => state.auth);
    const countrylistdata = useSelector(state => state.countrylistdata);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(auth && auth.profile && auth.profile.country_code ? auth.profile.country_code : t('all'));
    const [countryName, setCountryName] = useState('');
  
    useEffect(() => {
      if (countrylistdata.countries) {
        if (countrylistdata.countries) {
          setCountries(countrylistdata.countries);
        } else {
          setCountries([]);
        }
        if(auth.profile.usertype === 'admin' && auth.profile.country === undefined ){
          let country = countrylistdata.countries;
          for (let i = 0; i < country.length; i++) {
            if (country[i].pos === 0) {
              setSelectedCountry(country[i].country_code)
              setCountryName(country[i].country)
            }
          }
        }else{
          setSelectedCountry(auth.profile.country_code)
        }
      }
    }, [countrylistdata.countries, auth]);
    
    const handleCountrySelect = (event) => {
      setSelectedCountry(event.target.value);
  
      let country = countrylistdata.countries;
      for (let i = 0; i < country.length; i++) {
        if (country[i].country_code === event.target.value) {
          setCountryName(country[i].country)
          break;
        }else{
          setCountryName(t('all'))
        }
      }
    }

    useEffect(() => {
        dispatch(fetchUsersOnce());
    }, [dispatch, fetchUsersOnce])


    useEffect(() => {
        if (complaindata) {
          setData(complaindata.filter(promo => (promo.country === auth.profile.country) || ( auth.profile.usertype === 'admin' && auth.profile.country === undefined && (promo.country === countryName || countryName === "All"))));
        } else {
          setData([]);
        }
        loaded.current = true;
      }, [complaindata, auth, countryName]);


    // useEffect(() => {
    //     if (complaindata) {
    //         setData(complaindata);
    //     } else {
    //         setData([]);
    //     }
    //     loaded.current = true;
    // }, [complaindata]);

    const columns = [
        { title: t('createdAt'), field: 'createdAt', editable: 'never', defaultSort: 'desc', render: rowData => rowData.complainDate ? moment(rowData.complainDate).format('lll') : null },
        { title: t('first_name'), field: 'firstName', editable: 'never' },
        { title: t('last_name'), field: 'lastName', editable: 'never' },
        { title: t('usertype'), field: 'role', editable: 'never' },
        { title: t('email'), field: 'email', editable: 'never' },
        { title: t('mobile'), field: 'mobile', editable: 'never' },
        { title: t('message_text'), field: 'body', editable: 'never', initialEditValue: '',cellStyle:{wordWrap: "break-word", textAlign:"center", maxWidth:"420px"} },
        { title: t('subject'), field: 'subject', editable: 'never', initialEditValue: '' },
        { title: t('country'),field: 'country', editable: 'never' },
        { title: t('processDate'), field: 'processDate', editable: 'never', defaultSort: 'desc', render: rowData => rowData.processDate ? moment(rowData.processDate).format('lll') : null },
        { title: t('status'), field: 'check', type: 'boolean', initialEditValue: true, },
    ];

    const [selectedRow, setSelectedRow] = useState(null);

    return (
        !loaded.current ? <CircularLoading /> :
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', direction: isRTL === 'rtl' ? 'rtl' : 'ltr', }}>
                {auth.profile.usertype === 'admin' && auth.profile.country === undefined ?
                <FormControl sx={{ m: 1, width: 240 }}>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedCountry}
                    onChange={handleCountrySelect}
                    style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', border: '1px solid #9E9E9E', borderRadius: 50, height: 45 }}
                >
                    <MenuItem value={t('all')} style={{direction:isRTL==='rtl'?'rtl':'ltr', width:'100%', justifyContent:'flex-start', paddingLeft:10}}>
                    {t('all')}
                    </MenuItem>
                    {countries.map((item) =>
                    <MenuItem dense={true} key={item.country} value={item.country_code} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', width: '100%', justifyContent: 'flex-start', paddingLeft: 10 }}>
                        {t(item.country)}
                    </MenuItem>
                    )}
                </Select>
                </FormControl>
                : null}
            </div>
       
            <MaterialTable
                title={t('complain_title')}
                columns={columns}
                style={{ 
                    direction: isRTL === "rtl" ? "rtl" : "ltr",
                    borderRadius: "8px",
                    boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
                    padding:5 
                }}
                data={data}
                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                options={{
                    pageSize: 10,
                    pageSizeOptions: [10, 15, 20],
                    exportCsv: (columns, data) => {
                        let hArray = [];
                        const headerRow = columns.map(col => {
                            if (typeof col.title === 'object') {
                                return col.title.props.text;
                            }
                            hArray.push(col.field);
                            return col.title;
                        });
                        const dataRows = data.map(({ tableData, ...row }) => {
                            row.createdAt = row.complainDate ? new Date(row.complainDate).toLocaleDateString() + ' ' + new Date(row.complainDate).toLocaleTimeString() : '';
                            row.processDate = row.processDate ? new Date(row.processDate).toLocaleDateString() + ' ' + new Date(row.processDate).toLocaleTimeString() : '';
                            let dArr = [];
                            for (let i = 0; i < hArray.length; i++) {
                                dArr.push(row[hArray[i]]);
                            }
                            return Object.values(dArr);
                        })
                        const { exportDelimiter } = ",";
                        const delimiter = exportDelimiter ? exportDelimiter : ",";
                        const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join("\n");
                        const csvFileName = 'Complain.csv';
                        downloadCsv(csvContent, csvFileName);
                    },
                    exportButton: {
                        csv: settings.AllowCriticalEditsAdmin,
                        pdf: false,
                    },
                    maxColumnSort: "all_columns",
                    rowStyle: rowData => ({
                        backgroundColor: (selectedRow === rowData.tableData.id) ? colors.THIRDCOLOR : colors.WHITE
                    }),
                    editable: {
                        backgroundColor: colors.WHITE,
                        fontSize: "0.8em",
                        fontWeight: 'bold ',
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                        color: colors.BLACK,
                    },
                    headerStyle: {
                        position: "sticky",
                        top: "0px",
                        fontSize: "0.8em",
                        fontWeight: "bold ",
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                        color: colors.BLACK,
                        backgroundColor: SECONDORY_COLOR,
                        textAlign: "center",
                        border: "1px solid rgba(224, 224, 224, 1)",
                        minWidth:"60px"
                    },
                    cellStyle: {
 
                        textAlign: "center",
                    },
                    actionsColumnIndex: -1,
                }}
                localization={{
                    body: {
                        addTooltip: (t('add')),
                        deleteTooltip: (t('delete')),
                        editTooltip: (t('edit')),
                        emptyDataSourceMessage: (
                            (t('blank_message'))
                        ),
                        editRow: {
                            deleteText: (t('delete_message')),
                            cancelTooltip: (t('cancel')),
                            saveTooltip: (t('save'))
                        },
                    },
                    toolbar: {
                        searchPlaceholder: (t('search')),
                        exportTitle: (t('export')),
                    },
                    header: {
                        actions: (t('actions'))
                    },
                    pagination: {
                        labelDisplayedRows: ('{from}-{to} ' + (t('of')) + ' {count}'),
                        firstTooltip: (t('first_page_tooltip')),
                        previousTooltip: (t('previous_page_tooltip')),
                        nextTooltip: (t('next_page_tooltip')),
                        lastTooltip: (t('last_page_tooltip'))
                    },
                }}
                editable={{
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                resolve();
                                if (newData !== oldData) {
                                    delete newData.tableData;
                                    dispatch(editComplain(newData, 'Update'));
                                }
                            }, 600);
                        }),
                }}
            /> 
        </>
    );
}
