import AlertDialog from "../components/AlertDialog";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Typography, TextField, Button, Grid, Card } from "@mui/material";
import { api } from "common";
import { useTranslation } from "react-i18next";
import CircularLoading from "components/CircularLoading";
import { colors } from "components/Theme/WebTheme";
import { MAIN_COLOR, SECONDORY_COLOR } from "../common/sharedFunctions";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import CountryListSelect from '../components/CountryListSelect';

const useStyles = makeStyles((theme) => ({
  textField: {
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl: {
    "& label": {
      right: 0,
      left: "auto",
      paddingRight: 20
    },
    "& legend": {
      textAlign: "right",
      marginRight: 37
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl_2: {
    "& label": {
      right: 17,
      left: "auto",
      paddingRight: 12
    },
    "& legend": {
      textAlign: "right",
      marginRight: 25
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  selectField_rtl_2: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR,
    },
    "& label": {
      right: 50,
      left: "auto",
      paddingRight: 12,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 20,
    },
  },
  selectField_rtl_1: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR,
    },
    "& label": {
      right: 50,
      left: "auto",
      paddingRight: 12,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 25,
    },
  },

  selectField_rtl: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR,
    },
    "& label": {
      right: 50,
      left: "auto",
      paddingRight: 12,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 15,
    },
  },

  right: {
    textAlign: "right",
    right: 0,
    left: "auto",
    paddingRight: 40,
  },
  selectField: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR,
    },
  },
}));

const UpdateCountry = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { countries, editCountry, fetchUsersOnce } = api;
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [loding, setLoding] = useState(false);
  const classes = useStyles();
 
  const [countryCode, setCountryCode] = useState();
  const countrylistdata = useSelector(state => state.countrylistdata.countries);

  const [data, setData] = useState({
    conversion_ratio: "",
    country: "",
    currency_code: "",
    panic: "",
    pos: 0,
    referralbonus: 0,
    driverRadius: 0,
    driverThreshold: 0,
    serviceRadius: 0,
    shipperRadius: 0,
    swipe_symbol: false,
    symbol: "",
    walletMoneyField: ""
  });

useEffect(() => {
  if (data) {
    for (let i = 0; i < countries.length; i++) {
      if (data.country && countries[i].label === data.country) {
        setCountryCode(countries[i]);
        break;
      }else{
        if(!data.country){
          setCountryCode(countries[0]);
        }
      }
    }
  }
}, [data, countries]);

const handleChangeApproved = (event) => {
  setData({ ...data, swipe_symbol: event.target.value });
};

const handleConversionRatio = (e) => {
  setData({ ...data, conversion_ratio: parseFloat(e.target.value) >= 0 ? parseFloat(e.target.value) : '' });
};

const handleDriverRadius = (e) => {
  setData({ ...data, driverRadius: parseFloat(e.target.value) >= 0 ? parseFloat(e.target.value) : '' });
};

const handleDriverThreshold = (e) => {
  setData({ ...data, driverThreshold: parseFloat(e.target.value) >= 0 ? parseFloat(e.target.value) : '' });
};

const handleServiceRadius = (e) => {
  setData({ ...data, serviceRadius: parseFloat(e.target.value) >= 0 ? parseFloat(e.target.value) : '' });
};

const handleShipperRadius = (e) => {
  setData({ ...data, shipperRadius: parseFloat(e.target.value) >= 0 ? parseFloat(e.target.value) : '' });
};

const handlePanic = (e) => {
  setData({ ...data, panic: parseFloat(e.target.value) >= 0 ? parseFloat(e.target.value) : '' });
};

const handleReferralbonus = (e) => {
  setData({ ...data, referralbonus: parseFloat(e.target.value) >= 0 ? parseFloat(e.target.value) : '' });
};

const handlePos = (e) => {
  setData({ ...data, pos: parseFloat(e.target.value) >= 0 ? parseFloat(e.target.value) : '' });
};

  
  useEffect(() => {
    dispatch(fetchUsersOnce());
  }, [dispatch, fetchUsersOnce]);

  useEffect(() => {
    if (countrylistdata && id) {
      const country = countrylistdata.filter(
        (country) => country.id === id.toString()
      )[0];
      if (!country) {
        navigate("/404");
      }
      setData(country);
    } else {
      setData([]);
    }
  }, [countrylistdata, id, navigate]);


  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  const handleCountryChange = (value) => {
    setData({ ...data, country: value.label });
  };

  const handelSubmit = () => {
    if (!(data.symbol && data.panic && data.symbol.length>0 && data.currency_code && data.currency_code.length > 0 && data.conversion_ratio && data.conversion_ratio > 0 && data.driverRadius > 0 && data.shipperRadius > 0 && data.serviceRadius > 0 && data.pos >= 0)) {
      setCommonAlert({ open: true, msg: t("no_details_error") });
    } else {
      setLoding(true);
      setTimeout(() => {
        if(id){
          dispatch(editCountry({...data, country: countryCode.label, country_code: countryCode.code, phone_code: countryCode.phone, referralbonus : data.referralbonus > 0 ? data.referralbonus : 0 }, "Update"));
        }else{
          dispatch(editCountry({...data, country: countryCode.label, country_code: countryCode.code, phone_code: countryCode.phone, referralbonus : data.referralbonus > 0 ? data.referralbonus : 0  }, "Add"));
        }
        navigate("/countries");
        setLoding(false);
      }, 600);
    }
  };

  return loding ? (
    <CircularLoading />
  ) : (
    <div>
      <Card
        style={{
          borderRadius: "19px",
          backgroundColor: colors.WHITE,
          minHeight: 100,
          maxWidth: "75vw",
          marginTop: 20,
          marginBottom: 20,
          padding: 25,
          alignItems: "center",
          justifyContent: "center",
          boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
        }}
      >
        <Typography
          variant="h5"
          style={{
            marginTop: -15,
            textAlign: isRTL === "rtl" ? "right" : "left",
          }}
        >
           { id ? t("update_country_title") : t("add_country_title")}
        </Typography>
        <div
          dir={isRTL === "rtl" ? "rtl" : "ltr"}
        >
          <Button
            variant="text"
            onClick={() => {
              navigate("/countries");
            }}
          >
            <Typography
              style={{
                marginBottom: 10,
                textAlign: isRTL === "rtl" ? "right" : "left",
                fontWeight: "bold",
                color:MAIN_COLOR
              }}
            >
              {`<<- ${t("go_back")}`}
            </Typography>
          </Button>
        </div>

        <Grid
          container
          spacing={2}
          sx={{
            direction:isRTL === "rtl" ? "rtl" : "ltr",
            gridTemplateColumns: "50%",
          }}
        >
          <Grid item xs={12} sm={6} md={6} lg={6} >
            <CountryListSelect
              label={t('select_country')}
              dis={true}
              countries={countries}
              onlyCode={false}
              value={countryCode ? countryCode : ""}
              onChange={
                (object, value) => {
                  handleCountryChange(value);
                }
              }
              style={{ marginTop: 1 }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("conversion_ratio")}
              id={"conversion_ratio"}
              value={data?.conversion_ratio}
              fullWidth
              onChange={handleConversionRatio}
              className={isRTL === "rtl" ? classes.rootRtl : classes.textField}
              type = "number"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("currency_symbol")}
              id={"symbol"}
              value={data?.symbol}
              fullWidth
              onChange={handleInputChange}
              className={isRTL === "rtl" ? classes.rootRtl_2 : classes.textField}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("currency_code")}
              id={"currency_code"}
              value={data?.currency_code}
              fullWidth
              onChange={handleInputChange}
              className={isRTL === "rtl" ? classes.rootRtl_2 : classes.textField}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("driverRadius")}
              id={"driverRadius"}
              value={data.driverRadius ? data.driverRadius : 0}
              fullWidth
              onChange={handleDriverRadius}
              className={isRTL === "rtl" ? classes.rootRtl_2 : classes.textField}
              type = "number"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("driverThreshold")}
              id={"driverThreshold"}
              value={data.driverThreshold ? data.driverThreshold : 0}
              fullWidth
              onChange={handleDriverThreshold}
              className={isRTL === "rtl" ? classes.rootRtl : classes.textField}
              type = "number"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("panic")}
              id={"panic"}
              value={data.panic ? data.panic : ""}
              fullWidth
              onChange={handlePanic}
              className={isRTL === "rtl" ? classes.rootRtl : classes.textField}
              type = "number"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("referralbonus")}
              id={"referralbonus"}
              value={data.referralbonus ? data.referralbonus : 0}
              fullWidth
              onChange={handleReferralbonus}
              className={isRTL === "rtl" ? classes.rootRtl : classes.textField}
              type = "number"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("serviceRadius")}
              id={"serviceRadius"}
              value={data.serviceRadius ? data.serviceRadius : 0}
              fullWidth
              onChange={handleServiceRadius}
              className={isRTL === "rtl" ? classes.rootRtl : classes.textField}
              type = "number"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("shipperRadius")}
              id={"shipperRadius"}
              value={data.shipperRadius ? data.shipperRadius : 0}
              fullWidth
              onChange={handleShipperRadius}
              className={isRTL === "rtl" ? classes.rootRtl : classes.textField}
              type = "number"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("walletMoneyField")}
              id={"walletMoneyField"}
              value={data?.walletMoneyField}
              fullWidth
              onChange={handleInputChange}
              className={isRTL === "rtl" ? classes.rootRtl : classes.textField}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("position")}
              id={"pos"}
              value={data.pos ? data.pos : 0}
              fullWidth
              onChange={handlePos}
              className={isRTL === "rtl" ? classes.rootRtl : classes.textField}
              type = "number"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <FormControl fullWidth style={{ direction: isRTL === "rtl" ? "rtl" : 'ltr' }}>
              <InputLabel id="demo-simple-select-label" className={isRTL === "rtl" ? classes.right : ""} sx={{ "&.Mui-focused": { color: MAIN_COLOR } }}>
                {t("swipe_symbol")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.swipe_symbol ? data.swipe_symbol : false}
                label={t("swipe_symbol")}
                onChange={handleChangeApproved}
                className={isRTL === "rtl" ? classes.selectField_rtl : classes.selectField}
              >
                <MenuItem style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }} value={true}>{t("yes")}</MenuItem>
                <MenuItem style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }} value={false}>{t("no")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Button
              style={{
                borderRadius: "5px",
                backgroundColor: MAIN_COLOR,
                minHeight: 65,
                minWidth: "100%",
                textAlign:"center"
              }}
              onClick={handelSubmit}
              variant="contained"
            >
              <Typography
                style={{
                  color: colors.WHITE,
                  textAlign: "center",
                  fontSize: 16,
                  fontWeight:"bold"
                }}
              >
                {t("submit")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Card>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
};

export default UpdateCountry;
