import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import HandsOnMobile from '../assets/img/handsonmobile.jpg';
import useStyles from '../styles/styles';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
//import classNames from "classnames";

const DownloadApp = () => {
  const classes = useStyles();
  const settings = useSelector(state => state.settingsdata.settings);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();

  return (

 <Box className={classes.aboutUsContainer} style={{marginBottom: -15}}>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={12} md={5}>
          <img src={HandsOnMobile} alt="My Team" className={classes.largeImage} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h3" fontWeight={700} className={classes.title}>
            {t('mobile_apps_on_store')}
          </Typography>
          <Typography className={classes.aboutUsSubtitle}>
            {t('app_store_deception1')}
          </Typography>


          <Grid container style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr'}} >
            <Grid item xs={12} sm={12} md={12} lg={12} style={{display:'flex'}} >

              <Grid item xs={12} sm={4} md={4} lg={4} style={{display:'flex', justifyContent: 'center'}} >
                <Typography className={classes.aboutUsSubtitle} style={{marginRight: '20px', fontWeight:'bold', fontSize: 20}}>
                  {t('autopal')}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={8} md={8} lg={8} style={{display:'flex'}} >
                {settings && settings.AppleStoreLink?
                  <a href={settings.AppleStoreLink}><img src={require("../assets/img/appstore.png").default} alt="Apple Store Link"/></a>
                  :null}
                  <span style={{marginRight: '25px'}}></span>
                  {settings && settings.PlayStoreLink?
                  <a href={settings.PlayStoreLink}><img src={require("../assets/img/playstore.png").default} alt="Playstore Link"/></a>
                :null}
              </Grid>
            </Grid>
          </Grid>

          
          <Grid container style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr'}} >
            <Grid item xs={12} sm={12} md={12} lg={12} style={{display:'flex'}} >

              <Grid item xs={12} sm={4} md={4} lg={4} style={{display:'flex', justifyContent: 'center'}} >
                <Typography className={classes.aboutUsSubtitle} style={{marginRight: '20px', fontWeight:'bold', fontSize: 20}}>
                  {t('autopal_tower')}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={8} md={8} lg={8} style={{display:'flex'}} >
                {settings && settings.AppleStoreTowerLink?
                  <a href={settings.AppleStoreTowerLink}><img src={require("../assets/img/appstore.png").default} alt="Apple Store Link"/></a>
                  :null}
                  <span style={{marginRight: '25px'}}></span>
                  {settings && settings.PlayStoreTowerLink?
                  <a href={settings.PlayStoreTowerLink}><img src={require("../assets/img/playstore.png").default} alt="Playstore Link"/></a>
                :null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>

  );
};

export default DownloadApp;