/*eslint-disable*/
import React from "react";
// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import styles from "../../styles/downloadStyle.js";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function SectionDownload() {
  const settings = useSelector(state => state.settingsdata.settings);
  const classes = useStyles();
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  //const { t } = useTranslation();

  return (
    <div >
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer className={classes.textCenter} justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <h2 className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{textAlign : 'center',}} className={classes.title}>{t('mobile_apps_on_store')}</h2>
				{/*<img className={classes.triobanner} src={require("../../assets/img/triobanner.png").default} alt="App Banner" />*/}

                  <img style={{ maxWidth: '40%', height: 'auto' }} className={classes.triobanner} src={require("../../assets/img/triobanner.png").default} alt="App Banner" />


          </GridItem>
          <GridItem xs={12} sm={8} md={6} style={{paddingTop:30}}>
            {settings && settings.AppleStoreLink?
            <a href={settings.AppleStoreLink}><img src={require("../../assets/img/appstore.png").default} alt="Apple Store Link"/></a>
            :null}
            <span style={{marginRight: '5px'}}></span>
            {settings && settings.PlayStoreLink?
            <a href={settings.PlayStoreLink}><img src={require("../../assets/img/playstore.png").default} alt="Playstore Link"/></a>
            :null}
          </GridItem>
		  <GridItem xs={12} sm={8} md={6} style={{paddingTop:30}}>
			 {settings && settings.AppleStoreTowerLink?
            <a href={settings.AppleStoreTowerLink}><img src={require("../../assets/img/appstore.png").default} alt="Tower Apple Store Link"/></a>
            :null}
            <span style={{marginRight: '5px'}}></span>
            {settings && settings.PlayStoreTowerLink?
            <a href={settings.PlayStoreTowerLink}><img src={require("../../assets/img/playstore.png").default} alt="Tower Playstore Link"/></a>
            :null}
          </GridItem>
        </GridContainer>
      </div>
      </div>
    </div>
  );
}
