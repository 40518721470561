import React,{ useState, useEffect } from 'react';
import { downloadCsv } from '../common/sharedFunctions';
import MaterialTable from "material-table";
import CircularLoading from "../components/CircularLoading";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import {colors} from '../components/Theme/WebTheme';
import {SECONDORY_COLOR} from "../common/sharedFunctions"
import {FormControl, Select, MenuItem} from "@mui/material";

const Sos = () => {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector(state => state.settingsdata.settings);
  const columns =  [
    // { title: 'ID', field: 'bookingId',editable: 'never'},
    { title: t('name'),field: 'user_name',editable: 'never'},
    { title: t('contact'),field: 'contact',editable: 'never'},
    { title: t('country'),field: 'country',editable: 'never'},
    { title: t('user_type'),field: 'user_type',editable: 'never'},
    { title: t('Date'), field: 'complainDate', editable:'never', defaultSort:'desc',render: rowData => rowData.complainDate? moment(rowData.complainDate).format('lll'):null},
  ];
  const [data, setData] = useState([]);
  const sosdata = useSelector(state => state.sosdata);
  
  const auth = useSelector(state => state.auth);
  const countrylistdata = useSelector(state => state.countrylistdata);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(auth && auth.profile && auth.profile.country_code ? auth.profile.country_code : t('all'));
  const [countryName, setCountryName] = useState('');

  useEffect(() => {
    if (countrylistdata.countries) {
      if (countrylistdata.countries) {
        setCountries(countrylistdata.countries);
      } else {
        setCountries([]);
      }
      if(auth.profile.usertype === 'admin' && auth.profile.country === undefined ){
        let country = countrylistdata.countries;
        for (let i = 0; i < country.length; i++) {
          if (country[i].pos === 0) {
            setSelectedCountry(country[i].country_code)
            setCountryName(country[i].country)
          }
        }
      }else{
        setSelectedCountry(auth.profile.country_code)
      }
    }
  }, [countrylistdata.countries, auth]);
  
  const handleCountrySelect = (event) => {
    setSelectedCountry(event.target.value);

    let country = countrylistdata.countries;
    for (let i = 0; i < country.length; i++) {
      if (country[i].country_code === event.target.value) {
        setCountryName(country[i].country)
        break;
      }else{
        setCountryName(t('all'))
      }
    }
  }

  useEffect(() => {
    if (sosdata.sos) {
      setData(sosdata.sos.filter(sos => (sos.country === auth.profile.country) || ( auth.profile.usertype === 'admin' && auth.profile.country === undefined && (sos.country === countryName || countryName === "All"))));
    } else {
      setData([]);
    }
  }, [sosdata.sos, auth, countryName]);



  // useEffect(()=>{
  //       if(sosdata.sos){
  //           setData(sosdata.sos);
  //       }else{
  //         setData([]);
  //       }
  // },[sosdata.sos]);

  const [selectedRow, setSelectedRow] = useState(null);
  
  return (
    sosdata.loading? <CircularLoading/>:
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', direction: isRTL === 'rtl' ? 'rtl' : 'ltr', }}>
          {auth.profile.usertype === 'admin' && auth.profile.country === undefined ?
          <FormControl sx={{ m: 1, width: 240 }}>
          <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedCountry}
              onChange={handleCountrySelect}
              style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', border: '1px solid #9E9E9E', borderRadius: 50, height: 45 }}
          >
              <MenuItem value={t('all')} style={{direction:isRTL==='rtl'?'rtl':'ltr', width:'100%', justifyContent:'flex-start', paddingLeft:10}}>
              {t('all')}
              </MenuItem>
              {countries.map((item) =>
              <MenuItem dense={true} key={item.country} value={item.country_code} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', width: '100%', justifyContent: 'flex-start', paddingLeft: 10 }}>
                  {t(item.country)}
              </MenuItem>
              )}
          </Select>
          </FormControl>
          : null}
      </div>
      <MaterialTable
        title={t('sos_title')}
        columns={columns}
        style={{direction:isRTL ==='rtl'?'rtl':'ltr', borderRadius: "8px", boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,}}
        data={data}
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 15, 20],
          exportCsv: (columns, data) => {
            let hArray = [];
            const headerRow = columns.map(col => {
              if (typeof col.title === 'object') {
                return col.title.props.text;
              }
              hArray.push(col.field);
              return col.title;
            });
            const dataRows = data.map(({ tableData, ...row }) => {
              row.date = new Date(row.date).toLocaleDateString() + ' '+ new Date(row.date).toLocaleTimeString()
              row.processDate = row.processDate? new Date(row.processDate).toLocaleDateString() + ' '+ new Date(row.processDate).toLocaleTimeString(): ''
              let dArr = [];
              for(let i=0;i< hArray.length; i++) {
                dArr.push(row[hArray[i]]);
              }
              return Object.values(dArr);
            })
            const { exportDelimiter } = ",";
            const delimiter = exportDelimiter ? exportDelimiter : ",";
            const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join("\n");
            const csvFileName = 'download.csv';
            downloadCsv(csvContent, csvFileName);
          },
          exportButton: {
            csv: settings.AllowCriticalEditsAdmin,
            pdf: false,
          },
          rowStyle: rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
          }),
          editable:{
            backgroundColor: colors.Header_Text,
            fontSize: "0.8em",
            fontWeight: 'bold ',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          },
          headerStyle: {
            position: "sticky",
            top: "0px",
            fontSize: "0.8em",
            fontWeight: 'bold ',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            color: colors.BLACK,
            backgroundColor: SECONDORY_COLOR,
            textAlign: "center",
            border: "1px solid rgba(224, 224, 224, 1)",
            minWidth:"70px"
          },
          cellStyle: {

            textAlign: "center",
          },
          actionsColumnIndex: -1,
        }}
        localization={{
          toolbar: {
            searchPlaceholder: (t('search')),
            exportTitle: (t('export')),
          },
          header: {
            actions: (t('actions')) 
        },
        pagination: {
          labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
          firstTooltip: (t('first_page_tooltip')),
          previousTooltip: (t('previous_page_tooltip')),
          nextTooltip: (t('next_page_tooltip')),
          lastTooltip: (t('last_page_tooltip'))
        },
        }}
      />
    </>
  );
}

export default Sos;