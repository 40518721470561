import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from '../styles/staticPages.js';
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function AboutUs(props) {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const { ...rest } = props;
  const settings = useSelector(state => state.settingsdata.settings);

  return (
    <div style={{margin:'-8px'}}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/header-back.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
 
        <div className={classes.container}>
            <br/>
 <h2 className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}style={{textAlign:isRTL === 'rtl'?'right':'left', fontSize: 35, fontWeight: 'lighter', position: "relative",marginTop: "30px",minHeight: "32px",color: "#383838",textDecoration: "none"}}>{t('how_it_works_heading_main')}</h2>
            <p className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{textAlign:isRTL === 'rtl'?'right':'left',  color: 'black', fontSize: 20, fontWeight: 'bold',marginTop:30, textTransform: 'uppercase' }}>{t('how_it_works_heading1')}</p>
			<p className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{ textAlign: 'justify' }}>{t('how_it_works_content1')}</p>
			<p className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{textAlign:isRTL === 'rtl'?'right':'left',  color: 'black', fontSize: 20, fontWeight: 'bold',marginTop:30, textTransform: 'uppercase' }}>{t('how_it_works_heading2')}</p>
            <p className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}  style={{ textAlign: 'justify' }}>{t('how_it_works_content2')}</p>
			<p className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{textAlign:isRTL === 'rtl'?'right':'left',  color: 'black', fontSize: 20, fontWeight: 'bold',marginTop:30, textTransform: 'uppercase' }}>{t('how_it_works_heading3')}</p>
			<p className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}  style={{ textAlign: 'justify' }}>{t('how_it_works_content3')}</p>
			<p className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{textAlign:isRTL === 'rtl'?'right':'left',  color: 'black', fontSize: 20, fontWeight: 'bold',marginTop:30, textTransform: 'uppercase' }}>{t('how_it_works_heading4')}</p>
			<p className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{ textAlign: 'justify' }}>{t('how_it_works_content4')}</p>
			  {settings && settings.contact_email ?
                  <p className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{textAlign:isRTL === 'rtl'?'right':'left',  color: 'black', fontSize: 18, fontWeight: 'bold',marginTop:30}}><span className={classes.description}>{t('email')}: </span>
                      <a href={"mailto:" + settings.contact_email} className={classes.description}>{settings.contact_email}</a>
                   </p>
              : null}
            <br/>
        </div>
        </div>

      <Footer />
    </div>
  );
}
