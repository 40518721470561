import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl, Typography, Box } from '@mui/material';
import DashboardCard from '../components/DashboardCard';
import {
    GoogleMap,
    Marker,
    InfoWindow
} from "@react-google-maps/api";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
import Chart from 'react-apexcharts';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { api } from 'common';
import { colors } from 'components/Theme/WebTheme';
const Dashboard = () => {
    const [mylocation, setMylocation] = useState(null);
    const [locations, setLocations] = useState([]);
    const [dailygross, setDailygross] = useState(0);
    const [monthlygross, setMonthlygross] = useState(0);
    const [totalgross, setTotalgross] = useState(0);

    const [settings, setSettings] = useState({});
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir();
    const usersdata = useSelector(state => state.usersdata);
    const bookinglistdata = useSelector(state => state.bookinglistdata);
    const settingsdata = useSelector(state => state.settingsdata);
    const auth = useSelector(state => state.auth);
    const cars = useSelector(state => state.cartypes.cars);
    const [allCompleteCount, setAllCompleteCount] = useState([]);
    const [allCancelCount, setAllCancelCount] = useState([]);
    const [activeCount, setActiveCount] = useState(0);
    const [driverCount, setDriverCount] = useState(0);
    const [customerCount, setCustomerCount] = useState(0);
    const [shipperCount, setShipperCount] = useState(0);
    const [serviesCount, setServiesCount] = useState(0);
    const deliveryLocationData = useSelector(state => state.deliverylocationdata.deliverylocation);
    const dispatch = useDispatch();
    const [countries, setCountries] = useState([]);
    const countrylistdata = useSelector(state => state.countrylistdata);
    const [selectedCountry, setSelectedCountry] = useState(auth && auth.profile && auth.profile.country_code ? auth.profile.country_code : null);
    const [data, setData] = useState([]);
    const { fetchDrivers, clearFetchDrivers } = api;
    useEffect(() => {
        if (countrylistdata.countries) {
            setCountries(countrylistdata.countries);
        } else {
            setCountries([]);
        }
        if(auth.profile.usertype === 'admin' && auth.profile.country === undefined ){
            let country = countrylistdata.countries;
            for (let i = 0; i < country.length; i++) {
                if (country[i].pos === 0) {
                    setSelectedCountry(country[i].country_code)
                }
            }
        }
    }, [countrylistdata.countries, auth.profile.country, auth.profile.usertype]);

    useEffect(() => {
        if (countrylistdata.countries) {
            let country = countrylistdata.countries;
            for (let i = 0; i < country.length; i++) {
                if (selectedCountry === country[i].country_code) {
                    setData(country[i])
                }
            }
        }
    }, [selectedCountry, countrylistdata.countries]);

    useEffect(() => {
        dispatch(fetchDrivers('web'));
        return () => {
            dispatch(clearFetchDrivers());
        };
    }, [dispatch, fetchDrivers, clearFetchDrivers]);

    useEffect(() => {
        if (mylocation == null) {
            navigator.geolocation.getCurrentPosition(
                position => setMylocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }),
                error => console.log(error)
            );
        }
    }, [mylocation]);

    useEffect(() => {
        if (settingsdata.settings) {
            setSettings(settingsdata.settings);
        }
    }, [settingsdata.settings]);

    useEffect(() => {
        if (usersdata.drivers && bookinglistdata.bookings) {
            const liveBookings = bookinglistdata.bookings.filter(bkg => bkg.status === 'STARTED' && ((auth.profile.usertype === 'fleetadmin' && bkg.fleetadmin === auth.profile.uid) || (auth.profile.usertype === 'admin' && auth.profile.country === undefined) || (auth.profile.usertype === 'admin' && auth.profile.country && auth.profile.country.length > 0 && bkg.country === auth.profile.country_code)));
            const drivers = usersdata.drivers;
            let locs = [];
            for (let i = 0; i < drivers.length; i++) {
                if (drivers[i].location) {
                    let carImage = 'https://cdn.pixabay.com/photo/2012/04/15/22/09/car-35502__480.png';
                    let bookingRef = null;
                    for (let j = 0; j < cars.length; j++) {
                        if (cars[j].name === drivers[i].carType) {
                            carImage = cars[j].image;
                        }
                    }
                    for (let j = 0; j < liveBookings.length; j++) {
                        if (liveBookings[j].driver === drivers[i].uid) {
                            bookingRef = liveBookings[j].reference;
                        }
                    }
                    locs.push({
                        id: i,
                        lat: drivers[i].location.lat,
                        lng: drivers[i].location.lng,
                        drivername: drivers[i].firstName + ' ' + drivers[i].lastName,
                        carnumber: drivers[i].vehicleNumber,
                        cartype: drivers[i].carType,
                        carImage: carImage,
                        bookingRef: bookingRef
                    });
                }
            }
            setLocations(locs);
        }
    }, [usersdata.drivers, auth.profile.usertype, auth.profile.uid, cars, bookinglistdata.bookings, settings, auth.profile.country_code, auth.profile.country]);

    useEffect(() => {
        let allCompleteCount = [];
        let allCancelCount = [];
        let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        let allbookings = bookinglistdata.bookings ? bookinglistdata.bookings.filter(bkg => (auth.profile.usertype === 'fleetadmin' && bkg.fleetadmin === auth.profile.uid) || (auth.profile.usertype === 'admin' && auth.profile.country === undefined) || (auth.profile.usertype === 'admin' && auth.profile.country && auth.profile.country.length > 0 && bkg.country === auth.profile.country_code)) : []
        if (allbookings.length > 0) {
            let today = new Date();
            let monthlyTotal = 0;
            let yearlyTotal = 0;
            let todayTotal = 0;
            for (let i = 0; i < allbookings.length; i++) {
                if ((allbookings[i].status === 'PAID' || allbookings[i].status === 'COMPLETE') && ((allbookings[i].fleetadmin === auth.profile.uid && auth.profile.usertype === 'fleetadmin') || auth.profile.usertype === 'admin') && allbookings[i].country === selectedCountry) {
                    const { tripdate, convenience_fees, fleetCommission, discount } = allbookings[i];
                    let tDate = new Date(tripdate);
                    if (convenience_fees && parseFloat(convenience_fees) > 0 && auth.profile.usertype === 'admin') {
                        if (tDate.getDate() === today.getDate() && tDate.getMonth() === today.getMonth() && tDate.getFullYear() === today.getFullYear()) {
                            todayTotal = todayTotal + parseFloat(convenience_fees) - parseFloat(discount);
                        }
                        if (tDate.getMonth() === today.getMonth() && tDate.getFullYear() === today.getFullYear()) {
                            monthlyTotal = monthlyTotal + parseFloat(convenience_fees) - parseFloat(discount);
                        }
                        yearlyTotal = yearlyTotal + parseFloat(convenience_fees) - parseFloat(discount);
                    }
                    if (fleetCommission && parseFloat(fleetCommission) > 0 && auth.profile.usertype === 'fleetadmin') {
                        if (tDate.getDate() === today.getDate() && tDate.getMonth() === today.getMonth() && tDate.getFullYear() === today.getFullYear()) {
                            todayTotal = todayTotal + parseFloat(fleetCommission);
                        }
                        if (tDate.getMonth() === today.getMonth() && tDate.getFullYear() === today.getFullYear()) {
                            monthlyTotal = monthlyTotal + parseFloat(fleetCommission);
                        }
                        yearlyTotal = yearlyTotal + parseFloat(fleetCommission);
                    }
                }
            }
            for (let i = 0; i < months.length; i++) {
                let completeCount = 0;
                let cancelCount = 0;
                for (let j = 0; j < allbookings.length; j++) {
                    const { tripdate } = allbookings[j];
                    let tDate = new Date(tripdate);
                    if (months[i] === months[tDate.getMonth()] && (allbookings[j].status === 'PAID' || allbookings[j].status === 'COMPLETE')) {
                        completeCount = completeCount + 1;
                    }
                    if (months[i] === months[tDate.getMonth()] && allbookings[j].status === 'CANCELLED') {
                        cancelCount = cancelCount + 1;
                    }
                }
                allCompleteCount.push(completeCount);
                allCancelCount.push(cancelCount);
            }

            setAllCompleteCount(allCompleteCount);
            setAllCancelCount(allCancelCount);
            setDailygross(parseFloat(todayTotal).toFixed(settings.decimal));
            setMonthlygross(parseFloat(monthlyTotal).toFixed(settings.decimal));
            setTotalgross(parseFloat(yearlyTotal).toFixed(settings.decimal));
        }
    }, [bookinglistdata.bookings, settings, auth, selectedCountry]);
    // }, [bookinglistdata.bookings, settings, auth.profile.uid, auth.profile.usertype, auth.profile.country, auth.profile.country_code, selectedCountry]);

    const barChartOptions = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        colors: [colors.GREEN, colors.RED],
        options: {
            maintainAspectRatio: false,
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                ],
            },
            chart: {
                height: 350,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
        },
    };

    const barChart = [{
        name: t('completed_bookings'),
        data: allCompleteCount
    }, {
        name: t('cancelled_bookings'),
        data: allCancelCount
    }];

    useEffect(() => {
        const users = usersdata.users ? usersdata.users.filter(user => (user.country === auth.profile.country && auth.profile.usertype === 'admin' && auth.profile.country && auth.profile.country.length > 0) || (auth.profile.usertype === 'admin' && auth.profile.country === undefined)) : []
        if (users.length > 0) {
            let driverCount = 0;
            let activeCount = 0;
            let customerCount = 0;
            let shipperCount = 0;
            for (let i = 0; i < users.length; i++) {
                if (users[i].usertype === 'driver') {
                    if (((selectedCountry === users[i].country_code)) && ((auth.profile.usertype === 'fleetadmin' && users[i].fleetadmin === auth.profile.uid) || auth.profile.usertype === 'admin')) {
                        driverCount = driverCount + 1;
                    }
                }
                if (users[i].driverActiveStatus === true) {
                    if (((selectedCountry === users[i].country_code)) && ((auth.profile.usertype === 'fleetadmin' && users[i].fleetadmin === auth.profile.uid) || auth.profile.usertype === 'admin')) {
                        activeCount = activeCount + 1;
                    }
                }
                if (users[i].usertype === 'customer') {
                    if (((selectedCountry === users[i].country_code)) && ((auth.profile.usertype === 'fleetadmin' && users[i].fleetadmin === auth.profile.uid) || auth.profile.usertype === 'admin')) {
                        customerCount = customerCount + 1;
                    }
                }
                if (users[i].usertype === 'shipper' && (selectedCountry === users[i].country_code)) {
                    shipperCount = shipperCount + 1;
                }
            }
            setActiveCount(activeCount);
            setDriverCount(driverCount);
            setShipperCount(shipperCount);
            setCustomerCount(customerCount);
        } else {
            setActiveCount(0)
            setDriverCount(0)
            setShipperCount(0)
            setCustomerCount(0)
        }
    }, [usersdata.users, auth.profile, selectedCountry]);
    
    useEffect(() => {
        if (deliveryLocationData && deliveryLocationData.length > 0) {
            let serviesCount = 0;
            for (let i = 0; i < deliveryLocationData.length; i++) {
                if(deliveryLocationData[i].country_code === selectedCountry){
                    serviesCount = serviesCount + 1;
                }
            }
            setServiesCount(serviesCount);
        } else {
            setServiesCount(0);
        }
    }, [deliveryLocationData, selectedCountry]);
    
    const handleCountrySelect = (event) => {
        let arr = [];
        if (bookinglistdata.bookings) {
            for (let i = 0; i < bookinglistdata.bookings.length; i++) {
                if (bookinglistdata.bookings[i].country === event.target.value) {
                    arr.push(bookinglistdata.bookings[i])
                }
            }
        }
        setSelectedCountry(event.target.value);
    }

    return (
        bookinglistdata.loading || usersdata.loading ? <CircularLoading /> :
            <div>
                {/* {auth.profile.usertype === 'admin' && auth.profile.country === undefined ? */}
                <div style={{ display: 'flex', justifyContent: 'space-between', direction: isRTL === 'rtl' ? 'rtl' : 'ltr', }}>
                    <Typography variant="h5" style={{ textAlign: 'center', marginTop: 10, fontWeight: 'bold', marginBottom: 20 }}>{t('gross_earning')}</Typography>
                    {auth.profile.usertype === 'admin' && auth.profile.country === undefined ?
                    <FormControl sx={{ m: 1, width: 240 }}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedCountry}
                            onChange={handleCountrySelect}
                            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', border: '1px solid #9E9E9E', borderRadius: 50, height: 45 }}
                        >
                            {/* <MenuItem value={t('all')} style={{direction:isRTL==='rtl'?'rtl':'ltr', width:'100%', justifyContent:'flex-start', paddingLeft:10}}>
                                {t('all')}
                            </MenuItem> */}
                            {countries.map((item) =>
                                <MenuItem dense={true} key={item.country} value={item.country_code} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', width: '100%', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                    {t(item.country)}
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    : null}
                </div>
                  
                <Grid container direction="row" spacing={2}>
                    <Grid item xs style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
                        {settings.swipe_symbol === false ?
                            <DashboardCard crdStyle={{ display: 'flex', borderRadius: "19px", backgroundColor: '#CC3372', color: colors.WHITE }} title={t('today_text')} image={require("../assets/img/money1.jpg").default}>{data.symbol + ' ' + dailygross}</DashboardCard>
                            :
                            <DashboardCard crdStyle={{ display: 'flex', borderRadius: "19px", backgroundColor: '#CC3372', color: colors.WHITE }} title={t('today_text')} image={require("../assets/img/money1.jpg").default}>{dailygross + ' ' + data.symbol}</DashboardCard>
                        }
                    </Grid>
                    <Grid item xs style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        {settings.swipe_symbol === false ?
                            <DashboardCard crdStyle={{ display: 'flex', borderRadius: "19px", backgroundColor: '#A755C2', color: colors.WHITE }} title={t('this_month_text')} image={require("../assets/img/money2.jpg").default}>{data.symbol + ' ' + monthlygross}</DashboardCard>
                            :
                            <DashboardCard crdStyle={{ display: 'flex', borderRadius: "19px", backgroundColor: '#A755C2', color: colors.WHITE }} title={t('this_month_text')} image={require("../assets/img/money2.jpg").default}>{monthlygross + ' ' + data.symbol}</DashboardCard>
                        }
                    </Grid>
                    <Grid item xs style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        {settings.swipe_symbol === false ?
                            <DashboardCard crdStyle={{ display: 'flex', borderRadius: "19px", backgroundColor: '#2CDE3A', color: colors.WHITE }} title={t('total')} image={require("../assets/img/money3.jpg").default}>{data.symbol + ' ' + totalgross}</DashboardCard>
                            :
                            <DashboardCard crdStyle={{ display: 'flex', borderRadius: "19px", backgroundColor: '#2CDE3A', color: colors.WHITE }} title={t('total')} image={require("../assets/img/money3.jpg").default}>{totalgross + ' ' + data.symbol}</DashboardCard>
                        }
                    </Grid>
                </Grid>

                <Grid item xs={12} style={{ marginTop: 20 }}>
                    <Grid container style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }} >
                        <Grid item xs={12} sm={12} md={9} lg={9} style={{ backgroundColor: '#fff', borderRadius: 20, overflow: 'hidden', }} boxShadow={3}>
                            <Typography variant="h4" style={{ margin: "20px 20px 0 15px", textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>{t('real_time_driver_section_text')}</Typography>
                            {mylocation && mylocation.lat ?
                                <GoogleMap
                                    zoom={10}
                                    center={mylocation}
                                    mapContainerStyle={{ height: `488px` }}
                                >
                                    {locations.map(marker => (
                                        <Marker
                                            position={{ lat: marker.lat, lng: marker.lng }}
                                            key={marker.id}
                                            icon={{
                                                url: marker.carImage,
                                                scaledSize: new window.google.maps.Size(35, 25)
                                            }}

                                        >
                                            <InfoWindow
                                                position={{ lat: marker.lat, lng: marker.lng }}
                                                options={{ pixelOffset: new window.google.maps.Size(0, -32) }}
                                            >
                                                <div>{marker.drivername}<br />{marker.carnumber}</div>
                                            </InfoWindow>

                                        </Marker>
                                    ))}
                                </GoogleMap>
                                : null}
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} style={{ padding: 20, marginTop: 10 }}>
                            <Card style={{ borderRadius: "19px", backgroundColor: '#CC3372', minHeight: 80, marginBottom: 20 }}>
                                <Typography style={{ color: 'white', textAlign: 'center', fontSize: 18, marginTop: 10 }}>{t('total_cumtomer')}</Typography>
                                <Typography style={{ color: 'white', textAlign: 'center', fontSize: 24, fontWeight: 'bold' }}>{customerCount}</Typography>
                            </Card>
                            <Card style={{ borderRadius: "19px", backgroundColor: '#A755C2', minHeight: 80, marginBottom: 20 }}>
                                <Typography style={{ color: 'white', textAlign: 'center', fontSize: 18, marginTop: 10 }}>{t('total_drivers')}</Typography>
                                <Typography style={{ color: 'white', textAlign: 'center', fontSize: 24, fontWeight: 'bold' }}>{driverCount}</Typography>
                            </Card>
                            <Card style={{ borderRadius: "19px", backgroundColor: '#2CDE3A', minHeight: 80, marginBottom: 20 }}>
                                <Typography style={{ color: 'white', textAlign: 'center', fontSize: 18, marginTop: 10 }}>{t('active_driver')}</Typography>
                                <Typography style={{ color: 'white', textAlign: 'center', fontSize: 24, fontWeight: 'bold' }}>{activeCount}</Typography>
                            </Card>
                            {auth.profile.usertype === 'admin' ?
                            <>
                            <Card style={{ borderRadius: "19px", backgroundColor: '#06113C', minHeight: 80, marginBottom: 20 }}>
                                <Typography style={{ color: 'white', textAlign: 'center', fontSize: 18, marginTop: 10 }}>{t('total_shipper')}</Typography>
                                <Typography style={{ color: 'white', textAlign: 'center', fontSize: 24, fontWeight: 'bold' }}>{shipperCount}</Typography>
                            </Card>
                            <Card style={{ borderRadius: "19px", backgroundColor: '#00564c', minHeight: 80, marginBottom: 20 }}>
                                <Typography style={{ color: 'white', textAlign: 'center', fontSize: 18, marginTop: 10 }}>{t('total_services')}</Typography>
                                <Typography style={{ color: 'white', textAlign: 'center', fontSize: 24, fontWeight: 'bold' }}>{serviesCount}</Typography>
                            </Card>
                            </>
                            : null }
                        </Grid>

                    </Grid>
                </Grid>
                <Box style={{ padding: 10, marginTop: 20, borderRadius: 20, backgroundColor: '#fff' }} boxShadow={6}>
                    <Typography style={{ margin: "5px 20px 0 15px", color: colors.GREEN, fontWeight: "bold" }} variant="h5">{t('booking_chart')}</Typography>
                    <Chart
                        options={barChartOptions}
                        series={barChart}
                        type="area"
                        width="100%"
                        height={360}
                    />
                </Box>
            </div>

    )
}

export default Dashboard;