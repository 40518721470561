import React, { useEffect, useState, useRef } from 'react';
import {
  Grid,
  Typography,
  Modal,
} from '@mui/material';
import Header from "components/Header/Header.js";
import classNames from "classnames";
import HomeFooter from "components/Footer/HomeFooter.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import {colors} from '../components/Theme/WebTheme';
import Hero from "../components/Hero";
import Section from "../components/Section";
import Download from "../components/Download";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "components/CustomButtons/Button.js";
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
//import ProductSection from "./Sections/ProductSection.js";
//import SectionDownload from "./Sections/SectionDownload.js";

const dashboardRoutes = [];

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10
  },
  paper: {
    width:480,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  }
}));


export default function LandingPage(props) {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  //const { t } = useTranslation();
  const auth = useSelector(state => state.auth);
  const { ...rest } = props;
  const [role, setRole] = useState();
  const [loggedIn, setLoggedIn] = useState(false);
  const [countries, setCountries] = useState([]);
  const countrylistdata = useSelector(state => state.countrylistdata);
  const [selectedCountry, setSelectedCountry] = useState(auth && auth.profile && auth.profile.country_code ? auth.profile.country_code : null);
  const [localCountry, setLocalCountry] = useState();
  const [updatePhone, setUpdatePhone] = useState(false);
  const navigate = useNavigate();
  const rootRef = useRef(null);
  const classes = useStyles();
  const searchLocation = async () => {
    const response = await fetch('https://api.country.is', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'}
    })
    const json = await response.json();
    if(json){
      setLocalCountry(json.country);
    }
  };

  useEffect(() => {
    if (auth && auth.profile) {
      if(auth.profile.uid){
        let role = auth.profile.usertype;
        setRole(role);
      }
      if (auth && auth.profile && auth.profile.usertype === 'customer' && !auth.profile.mobile ) {
        setUpdatePhone(true);
      }
    }
  }, [auth]);

  useEffect(()=>{
    if(auth.profile && auth.profile.uid){
      setLoggedIn(true);
    }else{
      setLoggedIn(false);
    }
  },[auth.profile]);

  useEffect(() => {
    if(countrylistdata.countries){
      searchLocation();
      if (countrylistdata.countries) {
        setCountries(countrylistdata.countries);
      } else {
        setCountries([]);
      }
      if( loggedIn && auth.profile.usertype === 'admin' && auth.profile.country === undefined ){
        let country = countrylistdata.countries;
        for (let i = 0; i < country.length; i++) {
          if (country[i].country_code === localCountry) {
            setSelectedCountry(country[i].country_code);
            break
          }else{
            if (country[i].pos === 0) {
              setSelectedCountry(country[i].country_code)
            }
          }
        }
      }
      else if(loggedIn){
        setSelectedCountry(auth.profile.country_code)
      }else{
        let country = countrylistdata.countries;
        for (let i = 0; i < country.length; i++) {
          if (country[i].country_code === localCountry) {
            setSelectedCountry(country[i].country_code);
            break
          }else{
            if (country[i].pos === 0) {
              setSelectedCountry(country[i].country_code)
            }
          }
        }
      }
    }
  }, [countrylistdata.countries, loggedIn, auth.profile, localCountry]);

  const handleCountrySelect = (event) => {
    setSelectedCountry(event.target.value);
  }

  return (
    <div style={{backgroundColor:colors.LandingPage_Background,margin:'-8px'}}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks  handleCountrySelect ={handleCountrySelect} selectedCountry={selectedCountry} countries={countries}/>}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
	  
	  {/*Frontpage content area8*/}
      <Hero role={role}/>
      <Section role={role} color={colors} selectedCountry={selectedCountry}/>
	  
	   <div className={classNames(classes.main, classes.mainRaised)} >
	   <div className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
        <div style={{ backgroundColor: colors.LandingPage_Front, borderRadius: 6 }}>
          <div className={classes.container}>
			  <Download />
          </div>
        </div>
      </div>
	  </div>


      <HomeFooter />
	  
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={updatePhone}
        //onClose={handleWalletModalClose}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid container spacing={2} className={classes.paper}>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{marginBottom: '20px'}}>
            <Typography  style={{fontWeight:'bolder', marginBottom:10}}>
              {t('add_phoneno')}
            </Typography>
          </Grid>            
          <Grid item xs={12} sm={12} md={12} lg={12}>

            <Button variant="contained" color="primary" style={{marginLeft:10,backgroundColor:colors.GREEN}} onClick={() => navigate('/profile')}>
              {t('ok')}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}
