import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import {colors} from '../components/Theme/WebTheme';
import CountryListSelect from '../components/CountryListSelect';
import Switch from "@mui/material/Switch";
import { SECONDORY_COLOR } from "../common/sharedFunctions";
import { useNavigate } from "react-router-dom";

export default function Countries() {
  const navigate = useNavigate();
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    countries,
    editCountry
  } = api;
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);
  const countrylistdata = useSelector(state => state.countrylistdata);
  const dispatch = useDispatch();
  const handelSwipeSymbol = (rowData) => {
    dispatch(editCountry({ ...rowData, swipe_symbol: !rowData.swipe_symbol }, "Update"));
  };

  const columns = [
      { title: t('country'), field: 'country', 
      editComponent: props => (
        <CountryListSelect
            label={t('select_country')}
            dis={true}
            countries={countries}
            onlyCode={false}
            onChange={
                (object, value) => {
                    props.onChange(value.label)
                }
            }

        />
      ),
    },
    { title: t('code'), field: 'country_code', editable: 'never'},
    { title: t('phone_code'), field: 'phone_code', editable: 'never'},
    { title: t('currency_symbol'), field: 'symbol'},
    { title: t('currency_code'), field: 'currency_code'},
    { title: t('conversion_ratio'), field: 'conversion_ratio', type: 'numeric', initialEditValue: 1},
    { title: t('swipe_symbol'), field: 'swipe_symbol', type: 'boolean', editable: 'never', initialEditValue: false,
      render: (rowData) => {
        if (rowData.tableData?.editing || rowData.tableData?.id === undefined || rowData.tableData?.id === null) {
          return null;
        } else {
          return (
            <Switch
              checked={rowData.swipe_symbol}
              onClick={() => handelSwipeSymbol(rowData)}
              disabled={!settings.AllowCriticalEditsAdmin}
            />
          )
        }
      },
    },
    { title: t('referral_bonus'), field: 'referralbonus', type: 'numeric'},
    { title: t('driverRadius'), field: 'driverRadius', type: 'numeric'},
    { title: t('shipperRadius'), field: 'shipperRadius', type: 'numeric'},
    { title: t('serviceRadius'), field: 'serviceRadius', type: 'numeric'},
    { title: t('driver_threshold'), field: 'driverThreshold', type: 'numeric'},
    { title: t('wallet_money_field'), field: 'walletMoneyField'},
    { title: t('panic_num'), field: 'panic', type: 'numeric'},
    { title: t('position'), field: 'pos', type: 'numeric'},
  ];

  useEffect(() => {
    if (countrylistdata.countries) {
      setData(countrylistdata.countries);
    }else{
      setData([]);
    }
  }, [countrylistdata.countries]);

  const [selectedRow, setSelectedRow] = useState(null);

  return (
    countrylistdata.loading ? <CircularLoading /> :
      <MaterialTable
        title={t('countries')}
        columns={columns}
        style={{direction:isRTL ==='rtl'?'rtl':'ltr', borderRadius: "8px", boxShadow: "4px 4px 6px #9E9E9E"}}
        data={data}
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 15, 20],
          exportButton: true,
          rowStyle: rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ?  colors.THIRDCOLOR: colors.WHITE
          }),
          editable:{
            backgroundColor: colors.WHITE,
            fontSize: "0.8em",
            fontWeight: 'bold ',
            fontFamily: 'Lucida Console", "Courier New", monospace'
          },
          headerStyle: {
            position: "sticky",
            top: "0px",
            fontSize: "0.8em",
            fontWeight: "bold ",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            color: colors.BLACK,
            backgroundColor: SECONDORY_COLOR,
            textAlign: "center",
            border: "1px solid rgba(224, 224, 224, 1)",
          },
          cellStyle: {
            textAlign: "center",
          },
          actionsColumnIndex: -1,
        }}
        localization={{body:{
          addTooltip: (t('add')),
          deleteTooltip: (t('delete')),
          editTooltip: (t('edit')),
          emptyDataSourceMessage: (
            (t('blank_message'))
        ),
        editRow: { 
          deleteText: (t('delete_message')),
          cancelTooltip: (t('cancel')),
          saveTooltip: (t('save')) 
            }, 
          },
          toolbar: {
            searchPlaceholder: (t('search')),
            exportTitle: (t('export')),
          },
          pagination: {
            labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
            firstTooltip: (t('first_page_tooltip')),
            previousTooltip: (t('previous_page_tooltip')),
            nextTooltip: (t('next_page_tooltip')),
            lastTooltip: (t('last_page_tooltip'))
          },
        }}
        actions={[
          {
            icon: "add",
            tooltip: t("add_country_title"),
            isFreeAction: true,
            onClick: (event) => navigate("/countries/addcountry"),
          },
          {
            icon: 'edit',
            tooltip: t("edit"),
            onClick: (event,rowData) => navigate(`/countries/updatecountry/${rowData.id}`)
          },
        ]}
        editable={{
          onRowDelete: oldData =>
          settings.AllowCriticalEditsAdmin ?
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                dispatch(editCountry(oldData, "Delete"));
              }, 600);
            }):
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                alert(t("demo_mode"));
              }, 600);
            }),
        }}
      />
  );
}
