import React, { useState, useEffect, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import {colors} from '../components/Theme/WebTheme';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MAIN_COLOR, SECONDORY_COLOR } from "../common/sharedFunctions";
import { makeStyles } from "@mui/styles";
import {FormControl, Select, MenuItem} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  selectField: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR,
    },
  },
  dateTimePickerInput: {
    '& .MuiOutlinedInput-input': {
      color:MAIN_COLOR, 
    },
  },
}));
export default function Promos() {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    editPromo
  } = api;
  const settings = useSelector(state => state.settingsdata.settings);

  const inputRef = useRef(null);

  const [data, setData] = useState([]);
  const promodata = useSelector(state => state.promodata);
  const dispatch = useDispatch();
  const [sortedData, SetSortedData] = useState([]);
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);
  const countrylistdata = useSelector(state => state.countrylistdata);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(auth && auth.profile && auth.profile.country_code ? auth.profile.country_code : t('all'));
  const [selectedRow, setSelectedRow] = useState(null);
  const [countryName, setCountryName] = useState('');
  const [countryList, setCountryList] = useState();

  useEffect(() => {
    if (countrylistdata.countries) {
      if (countrylistdata.countries) {
        setCountries(countrylistdata.countries);
      } else {
        setCountries([]);
      }
      if(auth.profile.usertype === 'admin' && auth.profile.country === undefined ){
        let country = countrylistdata.countries;
        for (let i = 0; i < country.length; i++) {
          if (country[i].pos === 0) {
            setSelectedCountry(country[i].country_code);
            setCountryName(country[i].country)
          }
        }
      }else{
        //setSelectedCountry(auth.profile.country_code)
        setSelectedCountry(auth.profile.country)
      }
    }
  }, [countrylistdata.countries, auth]);

  useEffect(()=>{
    if(countries){
      let list = {};
      let country = countries.filter((user) =>(user.country === auth.profile.country) || ( auth.profile.usertype === 'admin' && auth.profile.country === undefined ));
      for(let i=0; i<country.length; i++){
        let obj = country[i];
        list[obj.country] = obj.country
      }
      setCountryList(list);
    }
  },[countries, auth])
  
  const handleCountrySelect = (event) => {
    setSelectedCountry(event.target.value);

    let country = countrylistdata.countries;
    for (let i = 0; i < country.length; i++) {
      if (country[i].country_code === event.target.value) {
        setCountryName(country[i].country)
        break;
      }else{
        setCountryName(t('all'))
      }
    }
  }

  const columns = [
   { title: t('createdAt'), field: 'createdAt', editable:'never', defaultSort:'desc',render: rowData => rowData.createdAt? moment(rowData.createdAt).format('lll') :null,
        exportTransformer: (rowData) => new Date(rowData.createdAt).toLocaleDateString() + ' '+ new Date(rowData.createdAt).toLocaleTimeString()},
    { title: t('promo_name'), field: 'promo_name',
  },
  {
    title: t('promo_code_web'), field: 'promo_code'
  },
    { title: t('description'), field: 'promo_description',
  },
    {
      title: t('type'),
      field: 'promo_discount_type',
      lookup: { flat: t('flat'), percentage: t('percentage')},
    },
    { title: t('promo_discount_value'), field: 'promo_discount_value', type: 'numeric',
  },
    { title: t('max_limit'), field: 'max_promo_discount_value', type: 'numeric',
  },
    { title: t('min_limit'), field: 'min_order', type: 'numeric',
  },
    { title: t('end_date'), field: 'promo_validity',  
    editComponent: props => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          inputRef={inputRef}
          renderInput={(props) => <TextField {...props} variant='outlined' className={classes.dateTimePickerInput} />}
          label="DateTimePicker"
          value={props.rowData.promo_validity ? props.rowData.promo_validity : new Date()}
          onChange={(newValue) => {
            props.onChange(newValue)
          }}
          autoFocus={false}
        />
      </LocalizationProvider>
    ),
    render: rowData => rowData.promo_validity?moment(rowData.promo_validity).format('lll'):null,
  },
    { title: t('promo_usage'), field: 'promo_usage_limit', type: 'numeric',
  },
  { title: t('show_in_list'),  field: 'promo_show', type:'boolean'
  },
    { title: t('promo_used_by'), field: 'user_avail', editable: 'never',
  },
  {title: t('country'), field: 'country', lookup: countryList, editable:'onAdd', hidden: (auth.profile.usertype === 'admin' && auth.profile.country === undefined) ? false : true,}
  ];

  useEffect(() => {
    if (promodata.promos) {
      setData(promodata.promos.filter(promo => (promo.country === auth.profile.country) || ( auth.profile.usertype === 'admin' && auth.profile.country === undefined && (promo.country === countryName || countryName === "All"))));
    } else {
      setData([]);
    }
  }, [promodata.promos, auth, countryName]);

  useEffect(()=>{
    if(data){
      SetSortedData(data.sort((a,b)=>(moment(b.createdAt) - moment(a.createdAt))))
    }
  },[data])

  return (
    promodata.loading ? <CircularLoading /> :
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', direction: isRTL === 'rtl' ? 'rtl' : 'ltr', }}>
        {auth.profile.usertype === 'admin' && auth.profile.country === undefined ?
        <FormControl sx={{ m: 1, width: 240 }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedCountry}
            onChange={handleCountrySelect}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', border: '1px solid #9E9E9E', borderRadius: 50, height: 45 }}
          >
            <MenuItem value={t('all')} style={{direction:isRTL==='rtl'?'rtl':'ltr', width:'100%', justifyContent:'flex-start', paddingLeft:10}}>
              {t('all')}
            </MenuItem>
            {countries.map((item) =>
              <MenuItem dense={true} key={item.country} value={item.country_code} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', width: '100%', justifyContent: 'flex-start', paddingLeft: 10 }}>
                {t(item.country)}
              </MenuItem>
            )}
          </Select>
        </FormControl>
        : null}
      </div>
      <MaterialTable
        title={t('promo_offer_title')}
        columns={columns}
        style={{
          direction: isRTL === "rtl" ? "rtl" : "ltr",
          borderRadius: "8px",
          boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
          padding: "20px",
        }}
        data={sortedData}
      
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 15, 20],
          rowStyle: rowData => ({
          backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF',
            border: "1px solid rgba(224, 224, 224, 1)",
        }),
          editable:{
            backgroundColor: colors.Header_Text,
            fontSize: "0.8em",
            fontWeight: 'bold ',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          },
          headerStyle: {
            position: "sticky",
            top: "0px",
            fontSize: "0.8em",
            fontWeight: 'bold ',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            color: colors.BLACK,
            backgroundColor: SECONDORY_COLOR,
            textAlign: "center",
            border: "1px solid rgba(224, 224, 224, 1)",
          },
          cellStyle: {

            textAlign: "center",
          },
          actionsColumnIndex: -1,
        }}
        localization={{body:{
          addTooltip: (t('add')),
          deleteTooltip: (t('delete')),
          editTooltip: (t('edit')),
          emptyDataSourceMessage: (
            (t('blank_message'))
        ),
        editRow: { 
          deleteText: (t('delete_message')),
          cancelTooltip: (t('cancel')),
          saveTooltip: (t('save')) 
          }, 
          },
          toolbar: {
            searchPlaceholder: (t('search')),
            exportTitle: (t('export')),
          },
          header: {
            actions: (t('actions')) 
        },
        pagination: {
          labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
          firstTooltip: (t('first_page_tooltip')),
          previousTooltip: (t('previous_page_tooltip')),
          nextTooltip: (t('next_page_tooltip')),
          lastTooltip: (t('last_page_tooltip'))
        },
        }}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                if(!(newData.promo_name && newData.promo_code && newData.promo_description && newData.promo_discount_value && newData.promo_discount_type && newData.promo_discount_value && newData.max_promo_discount_value && newData.min_order && newData.promo_usage_limit && (newData.min_order >= newData.max_promo_discount_value) && (newData.promo_discount_value>=newData.max_promo_discount_value) && (auth.profile.usertype === 'admin' && auth.profile.country === undefined ? newData.country : auth.profile.country ))){
                  alert(t('no_details_error'));
                  reject();
                }else{
                  newData['promo_code'] = newData.promo_code.toUpperCase();
                  newData['promo_show'] = newData.promo_show ? true : false;
                  newData['createdAt'] = new Date().getTime();
                  newData['country'] = auth.profile.usertype === 'admin' && auth.profile.country === undefined ? newData.country : auth.profile.country;
                  newData['promo_validity'] = newData.promo_validity? new Date(newData.promo_validity).getTime() : null;
                  dispatch(editPromo(newData,"Add"));
                  resolve();
                }
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            settings.AllowCriticalEditsAdmin?
            new Promise((resolve, reject) => {
              setTimeout(() => {
                if(!(newData.promo_name && newData.promo_code &&  newData.promo_description && newData.promo_discount_value && newData.promo_discount_type && newData.promo_discount_value && newData.max_promo_discount_value && newData.min_order && newData.promo_usage_limit && (newData.min_order >= newData.max_promo_discount_value) && (newData.promo_discount_value>=newData.max_promo_discount_value))){
                  alert(t('no_details_error'));
                  reject();
                }else{
                  resolve();
                  newData['promo_code'] = newData.promo_code.toUpperCase();
                  newData['promo_show'] = newData.promo_show ? true : false;
                  newData['promo_validity'] = newData.promo_validity? new Date(newData.promo_validity).getTime(): null;
                  if(newData !== oldData){
                    delete newData.tableData;
                    dispatch(editPromo(newData,"Update"));
                  }
                }
              }, 600);
            })
            :
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                alert(t('demo_mode'));
              }, 600);
            }),
          onRowDelete: oldData =>
            settings.AllowCriticalEditsAdmin?
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                dispatch(editPromo(oldData,"Delete"));
              }, 600);
            })
            :
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                alert(t('demo_mode'));
              }, 600);
            })
        }}
      />
    </>
  );
}
