export const FirebaseConfig = {
	"projectId": "autopal-upgrade",
	"appId": "1:715545645650:web:7460eb79b272892c58c58b",
	"databaseURL": "https://autopal-upgrade-default-rtdb.firebaseio.com",
	"storageBucket": "autopal-upgrade.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDB12DB3UaQMFaDmP8OdYxpVWLbo8f-yx4",
	"authDomain": "autopal-upgrade.firebaseapp.com",
	"messagingSenderId": "715545645650",
	"measurementId": "G-JMVTVWT3GM"
};