import React, { useState, useEffect } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Riders from './Riders';
import Drivers from './Drivers';
import Shipper from './Shipper';
import FleetAdmins from './FleetAdmins';
import CountryAdmin from './CountryAdmin';
import CreateAdmin from './CreateAdmin';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { makeStyles} from '@mui/styles';
import {MAIN_COLOR,SECONDORY_COLOR} from "../common/sharedFunctions"
import {FormControl, Select, MenuItem} from "@mui/material";

  const useStyles = makeStyles({
    tabs: {

      "& .MuiTabs-indicator": {
        backgroundColor: SECONDORY_COLOR,
        height: 3,
      },
      "& .MuiTab-root.Mui-selected": {
        color: MAIN_COLOR
      }
    }
  })
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Users() {
  const [value, setValue] = React.useState(0);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const auth = useSelector(state => state.auth);
  const [role, setRole] = React.useState(null);
  const classes = useStyles();

  React.useEffect(() => {
    if(auth.profile && auth.profile.usertype){
      setRole(auth.profile.usertype);
    }
  }, [auth.profile]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const countrylistdata = useSelector(state => state.countrylistdata);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(auth && auth.profile && auth.profile.country_code ? auth.profile.country_code : t('all'));

  useEffect(() => {
    if (countrylistdata.countries) {
      if (countrylistdata.countries) {
        setCountries(countrylistdata.countries);
      } else {
        setCountries([]);
      }
      if(auth.profile.usertype === 'admin' && auth.profile.country === undefined ){
        let country = countrylistdata.countries;
        for (let i = 0; i < country.length; i++) {
          if (country[i].pos === 0) {
            setSelectedCountry(country[i].country_code)
          }
        }
      }else{
        
        setSelectedCountry(auth.profile.country_code)
      }
    }
  }, [countrylistdata.countries, auth]);
  
  const handleCountrySelect = (event) => {
    setSelectedCountry(event.target.value);
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"  className={classes.tabs}>
          <Tab  label={t('riders')} {...a11yProps(0)} />
          <Tab  label={t('drivers')} {...a11yProps(1)} />
          {role === 'fleetadmin' || role === 'shipper' ?
            null : <Tab  label={t('shipper')} {...a11yProps(2)} />
          }
          {role === 'fleetadmin' || role === 'shipper' ?
            null : <Tab  label={t('fleetadmins')} {...a11yProps(3)} />
          }
          {role === 'fleetadmin' || role === 'shipper' || (role === 'admin'&& auth.profile.country && auth.profile.country.length> 0 )?
            null : <Tab label={t('countryadmin')} {...a11yProps(4)} />
          }
          {role === 'fleetadmin' || role === 'shipper' || (role === 'admin'&& auth.profile.country && auth.profile.country.length> 0 )?
            null : <Tab label={t('alladmins_title')} {...a11yProps(5)} />
          }
        </Tabs>
      </Box>

      <div style={{ display: 'flex', justifyContent: 'space-between', direction: isRTL === 'rtl' ? 'rtl' : 'ltr', }}>
        {auth.profile.usertype === 'admin' && auth.profile.country === undefined && value !== 5 ?
        <FormControl sx={{ m: 1, width: 240 }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedCountry}
            onChange={handleCountrySelect}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', border: '1px solid #9E9E9E', borderRadius: 50, height: 45 }}
          >
            <MenuItem value={t('all')} style={{direction:isRTL==='rtl'?'rtl':'ltr', width:'100%', justifyContent:'flex-start', paddingLeft:10}}>
              {t('all')}
            </MenuItem>
            {countries.map((item) =>
              <MenuItem dense={true} key={item.country} value={item.country_code} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', width: '100%', justifyContent: 'flex-start', paddingLeft: 10 }}>
                {t(item.country)}
              </MenuItem>
            )}
          </Select>
        </FormControl>
        : null}
      </div>

      <TabPanel value={value} index={0}>
        <Riders selectedCountry={selectedCountry}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Drivers selectedCountry={selectedCountry}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Shipper selectedCountry={selectedCountry}/>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <FleetAdmins selectedCountry={selectedCountry}/>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <CountryAdmin selectedCountry={selectedCountry}/>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <CreateAdmin selectedCountry={selectedCountry}/>
      </TabPanel>
    </Box>
  );
}