import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AlertDialog from "../components/AlertDialog";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Typography, TextField, Button, Grid, Card } from "@mui/material";
import { api } from "common";
import { useTranslation } from "react-i18next";
import CircularLoading from "components/CircularLoading";
import { MAIN_COLOR, SECONDORY_COLOR } from "../common/sharedFunctions";
import { makeStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { colors } from "../components/Theme/WebTheme";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl: {
    "& label": {
      right: 10,
      left: "auto",
      paddingRight: 15,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 20,
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  selectField: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR,
    },
  },
  rootRtl_2: {
    "& label": {
      right: 15,
      left: "auto",
      paddingRight: 23,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 25,
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl_1: {
    "& label": {
      right: 15,
      left: "auto",
      paddingRight: 25,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 25,
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
}));

const UpdateCountryAdmin = () => {
    const { id } = useParams();
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { addUser, checkUserExists, fetchUsersOnce, editUser } = api;
    const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
    const [loding, setLoding] = useState(false);
    const classes = useStyles();
    const countrylistdata = useSelector((state) => state.countrylistdata);
    const [countries, setCountries] = useState();
    const staticusers = useSelector((state) => state.usersdata.staticusers);
    const [data, setData] = useState({
        email: "",
        mobile: "",
        firstName: "",
        lastName: "",
        country:" "
    });


    useEffect(() => {
        dispatch(fetchUsersOnce());
    }, [dispatch, fetchUsersOnce]);

    useEffect(() => {
        if (staticusers && id) {
            const user = staticusers.filter(
                (user) => user.id === id.toString() && user.usertype === "admin"
            )[0];
            if (!user) {
                navigate("/404");
            }
            setData(user);
        } else {
          setData([]);
        }
    }, [staticusers, id, navigate]);


    const handleCountryChange = (event) => {
        setData({ ...data, country: event.target.value });
    };

    const handleCommonAlertClose = (e) => {
        e.preventDefault();
        setCommonAlert({ open: false, msg: "" });
    };

    const handleInputChange = (e) => {
        setData({ ...data, [e.target.id]: e.target.value });
    };

    const handelSubmit = () => {
        if(id){
            dispatch(editUser(data.id, { ...data }));
            setTimeout(() => {
                navigate("/users");
            }, 1000);
        }else{
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!(data.lastName && data.firstName)) {
            setCommonAlert({ open: true, msg: t("proper_input_name") });
            } else if (!re.test(data.email)) {
            setCommonAlert({ open: true, msg: t("proper_email") });
            } else if (!data.mobile) {
            setCommonAlert({ open: true, msg: t("proper_mobile") });
            } else {
            new Promise((resolve, reject) => {
                setLoding(true);
                setTimeout(() => {
                checkUserExists(data).then((res) => {
                    if (res.users && res.users.length > 0) {
                    setCommonAlert({ open: true, msg: t("user_exists") });
                    reject(new Error("User already exists"));
                    } else if (!(data && data.firstName)) {
                    setCommonAlert({ open: true, msg: t("proper_input_name") });
                    reject(new Error("Enter proper name"));
                    } else if (res.error) {
                    setCommonAlert({ open: true, msg: t("email_or_mobile_issue") });
                    reject(new Error("Email or Mobile issue"));
                    } else {
                    data["usertype"] = "admin";
                    data["createdAt"] = new Date().getTime();
                    data["approved"] = true;
                    dispatch(addUser(data));
                    dispatch(fetchUsersOnce());
                    navigate("/users");
                    resolve();
                    }
                }, 600);
                });
            }).catch((e) => {
                // console.error("caught an error:",e)
            }).finally(() => {
                setLoding(false);
            });
            }
        }
    };


  useEffect(() => {
    if (countrylistdata && countrylistdata.countries && countrylistdata.countries.length > 0) {
      const countries = countrylistdata.countries.sort((a, b) => a.pos - b.pos);
      let arr = [];
      for (let i = 0; i < countries.length; i++) {
        arr.push({
          label: countries[i].country,
          code: countries[i].country_code,
          phone: countries[i].phone_code,
        });
      }
      setCountries(arr);
    }
  }, [countrylistdata, countrylistdata.countries]);

  return loding ? (
    <CircularLoading />
  ) : (
    <div>
      <Card
        style={{
          borderRadius: "19px",
          backgroundColor: "#fff",
          minHeight: 100,
          maxWidth: "75vw",
          marginTop: 20,
          marginBottom: 20,
          padding: 25,
          alignItems: "center",
          justifyContent: "center",
          boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
        }}
      >
        <Typography
          variant="h5"
          style={{
            marginTop: -15,
            textAlign: isRTL === "rtl" ? "right" : "left",
          }}
        >
          {id ?  t("update_country_admin_title") : t("add_country_admin_title")}
        </Typography>
        <div
          dir={isRTL === "rtl" ? "rtl" : "ltr"}
        >
          <Button
            variant="text"
            onClick={() => {
              navigate("/users");
            }}
          >
            <Typography
              style={{
                marginBottom: 10,
                textAlign: isRTL === "rtl" ? "right" : "left",
                fontWeight: "bold",
                color: MAIN_COLOR,
              }}
            >
              {`<<- ${t("go_back")}`}
            </Typography>
          </Button>
        </div>
        <Grid
          container
          spacing={2}
          sx={{
            direction: isRTL === "rtl" ? "rtl" : "ltr",
            gridTemplateColumns: "50%",
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              className={
                isRTL === "rtl" ? classes.rootRtl_1 : classes.textField
              }
              label={t("firstname")}
              id="firstName"
              value={data.firstName}
              fullWidth
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              className={
                isRTL === "rtl" ? classes.rootRtl_1 : classes.textField
              }
              label={t("last_name")}
              id="lastName"
              value={data.lastName}
              fullWidth
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              className={isRTL === "rtl" ? classes.rootRtl : classes.textField}
              label={t("mobile")}
              id="mobile"
              value={data.mobile}
              fullWidth
              disabled={id ? true : false}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              className={
                isRTL === "rtl" ? classes.rootRtl_2 : classes.textField
              }
              label={t("email")}
              id="email"
              disabled={id ? true : false}
              value={data.email}
              fullWidth
              onChange={handleInputChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {countries && countries.length > 0 ?
              <FormControl fullWidth style={{ direction: isRTL === "rtl" ? "rtl" : 'ltr' }}>
              <InputLabel
                id="demo-simple-select-label"
                className={isRTL === "rtl" ? classes.right : ""}
                sx={{ "&.Mui-focused": { color: colors.MAIN_COLOR } }}
              >
                {t("select_country")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.country || ""}
                label={t("select_country")}
                onChange={handleCountryChange}
                className={isRTL === "rtl" ? classes.selectField_rtl : classes.selectField}
              >
              {countries.map((item, idx)=>(
                <MenuItem style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }} value={item.label} key = {idx}>{item.label}</MenuItem>
              ))}
              </Select>
            </FormControl>
              : null}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={5}
          >
            <Button
              style={{
                borderRadius: "5px",
                backgroundColor: MAIN_COLOR,
                minHeight: 65,
                marginBottom: 20,
                textAlign: "center",
                width: "50%",
              }}
              onClick={handelSubmit}
              variant="contained"
            >
              <Typography
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: 16,
                }}
                className={
                  isRTL === "rtl" ? classes.rootRtl : classes.textField
                }
              >
                {t("submit")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Card>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
};

export default UpdateCountryAdmin;