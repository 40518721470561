import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from '../styles/staticPages.js';
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ProductSection from "./Sections/ProductSection.js";
import SectionDownload from "./Sections/SectionDownload.js";
import { colors } from '../components/Theme/WebTheme';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function AboutUs(props) {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const { ...rest } = props;
  const settings = useSelector(state => state.settingsdata.settings);

  return (
    <div style={{margin:'-8px'}}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/header-back.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
 
        <div className={classes.container}>
            <br/>
            {/* <h2 style={{textAlign:isRTL === 'rtl'?'right':'left',position: "relative",marginTop: "30px",minHeight: "32px",color: "#383838",textDecoration: "none",[isRTL === "rtl" ? "marginRight" : "marginRight"]: isRTL ? "30px" : "0px",wordBreak: "break-word"}}>{t('about_us')}</h2> */}
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{ color: 'black', fontSize: 35, marginTop:30, fontWeight: 'lighter'}}>{t('about_us')}</p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{ color: 'black', fontSize: 20, fontWeight: 'bold',marginTop:30, textTransform: 'uppercase' }}>{settings.CompanySlug}</p>
			<p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{ textAlign: 'justify' }}>{t('about_us_content1')}</p>
			<p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{ color: 'black', fontSize: 20, fontWeight: 'bold',marginTop:30, textTransform: 'uppercase'  }}>{settings.CompanyDescription}</p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{ textAlign: 'justify' }}>{t('about_us_content2')}</p>
			<p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{ color: 'black', fontSize: 20, fontWeight: 'bold',marginTop:30, textTransform: 'uppercase'  }} >{t('about_us_heading3')}</p>
			<p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{ textAlign: 'justify' }}>{t('about_us_content3')}</p>
			<p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{ color: 'black', fontSize: 20, fontWeight: 'bold',marginTop:30, textTransform: 'uppercase'  }} >{t('about_us_heading4')}</p>
			<p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{ textAlign: 'justify' }}>{t('about_us_content4')}</p>
            <br/>
        </div>
    </div>

      {/*<div className={classNames(classes.main, classes.mainRaised)}>*/}
	  <div className={classNames(classes.main2, classes.mainRaised2)} style={{ marginTop: 1 }}>
	   <div className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
        <div style={{ backgroundColor: colors.LandingPage_Front, borderRadius: 10 }}>
          <div className={classes.container}>
            <ProductSection />
          </div>
        </div>
      </div>
	  </div>

	  

      {/*<div className={classNames(classes.main2, classes.mainRaised2)} style={{ marginTop: 1 }}>*/}
	   <div className={classNames(classes.main, classes.mainRaised)} >
	   <div className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
        <div style={{ backgroundColor: colors.LandingPage_Front, borderRadius: 10 }}>
          <div className={classes.container}>
            <SectionDownload />
          </div>
        </div>
      </div>
	  </div>

	  
      <Footer />
    </div>
  );
}
