import {
    FETCH_SERVICE,
    FETCH_SERVICE_SUCCESS,
    FETCH_SERVICE_FAILED,
    EDIT_SERVICE
  } from "../store/types";

  import {firebase} from "../config/configureFirebase";
  import {onValue, push, set, remove} from "firebase/database";

  export const fetchService = () => (dispatch) => {

    const {
      serviceRef
    } = firebase;

    dispatch({
      type: FETCH_SERVICE,
      payload: null
    });
    onValue(serviceRef, snapshot => {
      if (snapshot.val()) {
        const data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          return data[i]
        });
        dispatch({
          type: FETCH_SERVICE_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_SERVICE_FAILED,
          payload: "No sevice available."
        });
      }
    });
  };


  export const editService = (service, method) => (dispatch)=>{

    const {serviceRef,serviceEditRef} = firebase;
    dispatch({
        type:EDIT_SERVICE,
        payload:{method, service}
    });
    if(method === "Add"){
        push(serviceRef, service);
    }else if (method === "Delete"){
        remove(serviceEditRef(service.id));
    }else{
        set(serviceEditRef(service.id), service);
    }
}