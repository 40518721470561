import React,{ useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import {colors} from '../components/Theme/WebTheme';
import {  useNavigate } from 'react-router-dom';
import { SECONDORY_COLOR } from "../common/sharedFunctions";
import moment from 'moment/min/moment-with-locales';
import {FormControl, Select, MenuItem} from "@mui/material";

export default function Notifications() {
  const { t, i18n  } = useTranslation();
  const isRTL = i18n.dir();
  const {
    editNotifications
  } = api;
  const auth = useSelector(state => state.auth);
  const countrylistdata = useSelector(state => state.countrylistdata);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(auth && auth.profile && auth.profile.country_code ? auth.profile.country_code : t('all'));
  const [countryName, setCountryName] = useState('');

  useEffect(() => {
    if (countrylistdata.countries) {
      if (countrylistdata.countries) {
        setCountries(countrylistdata.countries);
      } else {
        setCountries([]);
      }
      if(auth.profile.usertype === 'admin' && auth.profile.country === undefined ){
        let country = countrylistdata.countries;
        for (let i = 0; i < country.length; i++) {
          if (country[i].pos === 0) {
            setSelectedCountry(country[i].country_code)
            setCountryName(country[i].country)
          }
        }
      }else{
        setSelectedCountry(auth.profile.country_code)
      }
    }
  }, [countrylistdata.countries, auth]);
  
  const handleCountrySelect = (event) => {
    setSelectedCountry(event.target.value);

    let country = countrylistdata.countries;
    for (let i = 0; i < country.length; i++) {
      if (country[i].country_code === event.target.value) {
        setCountryName(country[i].country)
        break;
      }else{
        setCountryName(t('all'))
      }
    }
  }

  const columns =  [
    { title:t("createdAt"), field:"createdAt", defaultSort:'desc',render: rowData => rowData.createdAt? moment(rowData.createdAt).format('lll'):null },
    { title: t('device_type'), field: 'devicetype', lookup: { All: (t('all')), ANDROID: (t('android')), IOS: (t('ios')) }},
    { title: t('user_type'), field: 'usertype', lookup: { customer: t('customer'), driver: t('driver'), All: t('all') }},
    { title: t('country'),field: 'country' },
    { title: t('title'),field: 'title' },
    { title: t('body'), field: 'body' },
  ];

  const [data, setData] = useState([]);
  const notificationdata = useSelector(state => state.notificationdata);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    if (notificationdata.notifications) {
      setData(notificationdata.notifications.filter(promo => (promo.country === auth.profile.country) || ( auth.profile.usertype === 'admin' && auth.profile.country === undefined && (promo.country === countryName || countryName === "All"))));
    } else {
      setData([]);
    }
  }, [notificationdata.notifications, auth, countryName]);



  // useEffect(()=>{
  //       if(notificationdata.notifications){
  //         let noti = notificationdata.notifications;
  //         for(let i = 0 ; i < noti.length ; i++){

  //         }
  //           setData(notificationdata.notifications);
  //       }else{
  //           setData([]);
  //       }
  // },[notificationdata.notifications]);

  const [selectedRow, setSelectedRow] = useState(null);
  
  return (
    notificationdata.loading? <CircularLoading/>:
    <>
    <div style={{ display: 'flex', justifyContent: 'space-between', direction: isRTL === 'rtl' ? 'rtl' : 'ltr', }}>
        {auth.profile.usertype === 'admin' && auth.profile.country === undefined ?
        <FormControl sx={{ m: 1, width: 240 }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedCountry}
            onChange={handleCountrySelect}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', border: '1px solid #9E9E9E', borderRadius: 50, height: 45 }}
          >
            <MenuItem value={t('all')} style={{direction:isRTL==='rtl'?'rtl':'ltr', width:'100%', justifyContent:'flex-start', paddingLeft:10}}>
              {t('all')}
            </MenuItem>
            {countries.map((item) =>
              <MenuItem dense={true} key={item.country} value={item.country_code} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', width: '100%', justifyContent: 'flex-start', paddingLeft: 10 }}>
                {t(item.country)}
              </MenuItem>
            )}
          </Select>
        </FormControl>
        : null}
      </div>
    <MaterialTable
      title={t('push_notification_title')}
      columns={columns}
      style={{
        direction: isRTL === "rtl" ? "rtl" : "ltr",
        borderRadius: "8px",
        boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
        padding: "20px",
      }}
      data={data}
      
      onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
      options={{
        pageSize: 10,
        pageSizeOptions: [10, 15, 20],
        rowStyle: rowData => ({
          backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF',
          border: "1px solid rgba(224, 224, 224, 1)",
        }),
        editable:{
          backgroundColor: colors.WHITE,
          fontSize: "0.8em",
          fontWeight: 'bold ',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        },
        headerStyle: {
          position: "sticky",
          top: "0px",
          fontSize: "0.8em",
          fontWeight: 'bold ',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          color: colors.BLACK,
          backgroundColor: SECONDORY_COLOR,
          textAlign: "center",
          border: "1px solid rgba(224, 224, 224, 1)",
          minWidth:"70px"
        },
        cellStyle: {

          textAlign: "center",
        },
        actionsColumnIndex: -1,
      }}
      localization={{body:{
        addTooltip: (t('add')),
        deleteTooltip: (t('delete')),
        editTooltip: (t('edit')),
        emptyDataSourceMessage: (
          (t('blank_message'))
      ),
      editRow: { 
        deleteText: (t('delete_message')),
        cancelTooltip: (t('cancel')),
        saveTooltip: (t('save')) 
        }, 
        },
        toolbar: {
          searchPlaceholder: (t('search')),
          exportTitle: (t('export')),
        },
        header: {
          actions: (t('actions')) 
      },
      pagination: {
        labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
        firstTooltip: (t('first_page_tooltip')),
        previousTooltip: (t('previous_page_tooltip')),
        nextTooltip: (t('next_page_tooltip')),
        lastTooltip: (t('last_page_tooltip'))
      },
      }}
      editable={{
       
        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(editNotifications(oldData,"Delete"));
            }, 600);
          }),
      }}
      actions={[
        {
          icon: 'add',
          tooltip: t("add_notification"),
          isFreeAction: true,
          onClick: (event) => navigate("/notifications/addnotifications")
        },
        
       
      ]}
    />
    </>
  );
}
