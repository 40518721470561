import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import ServiceType from "./ServiceType";
//import ServiceMaster from "./ServiceMaster";
import AddDeliveryLocation from "./AddDeliveryLocation";
import DeliveryLocations from "./DeliveryLocations";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles} from '@mui/styles';
import {MAIN_COLOR,SECONDORY_COLOR} from "../common/sharedFunctions"

  const useStyles = makeStyles({
  tabs: {

    "& .MuiTabs-indicator": {
      backgroundColor: SECONDORY_COLOR,
      height: 3,
    },
    "& .MuiTab-root.Mui-selected": {
      color: MAIN_COLOR
    }
  }
})
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Location() {
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();
  const classes = useStyles();

  const auth = useSelector(state => state.auth);
  const [role, setRole] = React.useState(null);
  React.useEffect(() => {
    if(auth.profile && auth.profile.usertype){
      setRole(auth.profile.usertype);
    }
  }, [auth.profile]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"  className={classes.tabs}>
          <Tab  label={t('deliveryLocations_title')} {...a11yProps(0)} />
          <Tab  label={t('add_dropoff_location')} {...a11yProps(1)} />
          {(role === 'admin' && auth.profile.country && auth.profile.country.length > 0) ? null :
          <Tab  label={t('service_type')} {...a11yProps(2)} /> }
		  <Tab  label={t('service_master')} {...a11yProps(3)} /> }
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <DeliveryLocations/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AddDeliveryLocation/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ServiceType/>
      </TabPanel>
	   <TabPanel value={value} index={3}>
		   {/*<ServiceMaster/>*/}
      </TabPanel>
    </Box>
  );
}