import React, { useState, useEffect} from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import AlertDialog from "../components/AlertDialog";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import {colors} from '../components/Theme/WebTheme';
import Switch from "@mui/material/Switch";
import {SECONDORY_COLOR } from "../common/sharedFunctions";

export default function ServiceType() {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    editService
  } = api;
  const settings = useSelector(state => state.settingsdata.settings);
  const auth = useSelector((state) => state.auth);
  const [role, setRole] = useState(null);

  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
   
  const columns = [
    
    { title: t("service_name"), field: "service_name" },
    { title: t("service_code"), field: "service_code", editable:'never', initialEditValue: '',cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'center' }},
    {
      title: t("active"),
      field: "active",
      type: "boolean",
      //editable: "never",
      initialEditValue: false,
      render: (rowData) => (
        <Switch
          checked={rowData.active}
          onChange={() => handelActiveStatus(rowData)}
        />
      ),
    },
    {
        title: t("createdAt"),
        field: "createdAt",
        editable: "never",
        defaultSort: "desc",
        render: (rowData) =>
          rowData.createdAt ? moment(rowData.createdAt).format("lll") : null,
      },
  ];

  useEffect(() => {
    if (auth.profile && auth.profile.usertype) {
      setRole(auth.profile.usertype);
    }
  }, [auth.profile]);

  const handelActiveStatus = (rowData) => {
    if (settings.AllowCriticalEditsAdmin && role === "admin") {
        const updateData = { ...rowData, active: !rowData.active, tableData: null };
        dispatch(editService(updateData,"Update"));
    }else{
      setCommonAlert({ open: true, msg: t("demo_mode") });
    }
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  const [data, setData] = useState([]);
  const servicedata = useSelector(state => state.servicedata);
  const dispatch = useDispatch();
  const servicePrefix = 'SRV';

  useEffect(() => {
    if (servicedata && servicedata.service) {
      setData(servicedata.service);
    } else {
      setData([]);
    }
  }, [servicedata]);

  const [selectedRow, setSelectedRow] = useState(null);

  return (
    servicedata?.loading ? <CircularLoading /> :
    <>
    <MaterialTable
      title={t('service_type_title')}
      columns={columns}
        style={{
          direction: isRTL === "rtl" ? "rtl" : "ltr",
          borderRadius: "8px",
          boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
          padding: "20px",
        }}
        data={data}
        onRowClick={(evt, selectedRow) =>
          setSelectedRow(selectedRow.tableData.id)
        }
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 15, 20],
          exportButton: true,
          rowStyle: (rowData) => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF',
            border: "1px solid rgba(224, 224, 224, 1)",
          }),
          editable: {
            backgroundColor: colors.Header_Text,
            fontSize: "0.8em",
            fontWeight: "bold ",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',          
          },
          headerStyle: {
            position: "sticky",
            top: "0px",
            fontSize: "0.8em",
            fontWeight: "bold ",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            color: colors.BLACK,
            backgroundColor: SECONDORY_COLOR,
            textAlign: "center",
            border: "1px solid rgba(224, 224, 224, 1)",
          },
          cellStyle: {

            textAlign: "center",
          },
          actionsColumnIndex: -1,
        }}
        localization={{
          body: {
            addTooltip: t("add"),
            deleteTooltip: t("delete"),
            editTooltip: t("edit"),
            emptyDataSourceMessage: t("blank_message"),
            editRow: {
              deleteText: t("delete_message"),
              cancelTooltip: t("cancel"),
              saveTooltip: t("save"),
            },
          },
          toolbar: {
            searchPlaceholder: t("search"),
            exportTitle: t("export"),
          },
          header: {
            actions: t("actions"),
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
            firstTooltip: t("first_page_tooltip"),
            previousTooltip: t("previous_page_tooltip"),
            nextTooltip: t("next_page_tooltip"),
            lastTooltip: t("last_page_tooltip"),
          },
        }}
      editable={{
        onRowAdd: newData =>
        settings.AllowCriticalEditsAdmin ?
          new Promise((resolve, reject) => {
            setTimeout(() => {
              //if(!(newData.service_name &&  newData.service_code)){
				if(!(newData.service_name)){
                alert(t('no_details_error'));
                reject();
              }else{
                newData['createdAt'] = new Date().getTime();
                //newData['service_code'] = newData.service_code.toUpperCase();
				newData['service_code'] = servicePrefix.toUpperCase() + Math.floor(1000 + Math.random() * 9000).toString();
                console.log(newData)
                dispatch(editService(newData,"Add"));
                resolve();
              }
            }, 600);
          })
          :
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              setCommonAlert({ open: true, msg: t("demo_mode") });
            }, 600);
          }),
        onRowUpdate: (newData, oldData) =>
          settings.AllowCriticalEditsAdmin?
          new Promise((resolve, reject) => {
            setTimeout(() => {
                if(!(newData.service_code &&  newData.service_name)){
                  setCommonAlert({ open: true, msg: t("no_details_error") });
                reject();
              }else{
                resolve();
                if(newData !== oldData){
                  //newData['service_code'] = newData.service_code.toUpperCase();
				  //newData['service_code'] = servicePrefix.toUpperCase() + Math.floor(1000 + Math.random() * 9000).toString();
                  delete newData.tableData;
                  dispatch(editService(newData,"Update"));
                }
              }
            }, 600);
          })
          :
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              setCommonAlert({ open: true, msg: t("demo_mode") });
            }, 600);
          }),
        onRowDelete: oldData =>
          settings.AllowCriticalEditsAdmin?
          new Promise((resolve,reject) => {
            setTimeout(() => {
              if(oldData.active){
                reject();
                setCommonAlert({ open: true, msg: t("activeService_delete") });
              }else{
              resolve();
              dispatch(editService(oldData,"Delete"));
              }
            }, 600);
          })
          :
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              setCommonAlert({ open: true, msg: t("demo_mode") });
            }, 600);
          })
      }}
    />
    <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </>
  );

}