import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import AlertDialog from "../components/AlertDialog";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Card,
  Avatar,
} from "@mui/material";
import { api } from "common";
import { useTranslation } from "react-i18next";
import CircularLoading from "components/CircularLoading";
import { colors } from "components/Theme/WebTheme";
import { MAIN_COLOR, SECONDORY_COLOR } from "../common/sharedFunctions";
import { makeStyles } from "@mui/styles";
import GoogleMapsAutoComplete from 'components/GoogleMapsAutoComplete.js';

const useStyles = makeStyles((theme) => ({
  textField: {
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  selectField: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR,
    },
  },
  rootRtl: {
    "& label": {
      right: 10,
      left: "auto",
      paddingRight: 20,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 20,
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl_1: {
    "& label": {
      right: 15,
      left: "auto",
      paddingRight: 25,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 25,
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl_2: {
    "& label": {
      right: 10,
      left: "auto",
      paddingRight: 17,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 25,
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  selectField_rtl: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR,
    },
    "& label": {
      right: 10,
      left: "auto",
      paddingRight: 12,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 25,
    },
  },

  right: {
    textAlign: "right",
    right: 0,
    left: "auto",
    paddingRight: 35,
  },
}));
const UpdateShipper = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const staticusers = useSelector((state) => state.usersdata.staticusers);
  const settings = useSelector((state) => state.settingsdata.settings);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileInputRef = useRef();
  const loaded = useRef(false);
  const { fetchUsersOnce, updateCustomerProfileImage, editUser, addUser, checkUserExists } = api;
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [data, setData] = useState(null);
  const [oldData, setOldData] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState();
  const countrylistdata = useSelector((state) => state.countrylistdata);
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchUsersOnce());
  }, [dispatch, fetchUsersOnce]);

  useEffect(() => {
    if (staticusers && id) {
      const user = staticusers.filter(
        (user) => user.id === id.toString() && user.usertype === "shipper"
      )[0];
      if (!user) {
        navigate("/404");
      }
      setData(user);
      setOldData(user);
    } else {
      setData([]);
    }
  }, [staticusers, id, navigate]);


  useEffect(() => {
    if (countrylistdata && countrylistdata.countries && countrylistdata.countries.length > 0) {
      const countries = countrylistdata.countries.sort((a, b) => a.pos - b.pos);
      let arr = [];
      for (let i = 0; i < countries.length; i++) {
        if(auth && auth.profile.country_code && auth.profile.country_code.length > 0 && auth.profile.country_code === countries[i].country_code && data){
          setData({ ...data, country: countries[i].country, country_code: countries[i].country_code , currency_code: countries[i].currency_code, swipe_symbol: countries[i].swipe_symbol, symbol: countries[i].symbol});
        }
        arr.push({
          label: countries[i].country,
          code: countries[i].country_code,
          phone: countries[i].phone_code,
        });
      }
      setCountries(arr);
    }
  }, [countrylistdata, countrylistdata.countries, data, auth ]);

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };
  const handleChangeApproved = (event) => {
    setData({ ...data, approved: event.target.value });
  };

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  const profileImageChange = async (e) => {
    setProfileImage(e.target.files[0]);
  };

  // const handleCountryChange = (event) => {
  //   setData({ ...data, country: event.target.value });
  // };

  const handleCountryChange = (event) => {
    if (countrylistdata && countrylistdata.countries && countrylistdata.countries.length > 0) {
      const countries = countrylistdata.countries.sort((a, b) => a.pos - b.pos);
      for (let i = 0; i < countries.length; i++) {
        if(countries[i].country === event.target.value){
          setData({ ...data, country: event.target.value, country_code: countries[i].country_code , currency_code: countries[i].currency_code, swipe_symbol: countries[i].swipe_symbol, symbol: countries[i].symbol});
        }
      }
    }
  };


  const handleUpdate = () => {
    if(id){
      if (data === oldData && profileImage === null) {
        setCommonAlert({ open: true, msg: t("make_changes_to_update") });
      } else {
        setLoading(true);
        if (profileImage) {
          updateCustomerProfileImage(profileImage, data.id).then(() => {
            dispatch(fetchUsersOnce());
          });
        }

        dispatch(editUser(data.id, { ...data }));
        setTimeout(() => {
          setProfileImage(null);
          navigate("/users");
          setLoading(false);
        }, 1000);
      }
      loaded.current = true;
    }else{
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!data.lastName || !data.firstName) {
      setCommonAlert({ open: true, msg: t("proper_input_name") });
    } else if (!re.test(data.email)) {
      setCommonAlert({ open: true, msg: t("proper_email") });
    } else if (!data.mobile) {
      setCommonAlert({ open: true, msg: t("proper_mobile") });
    } else if (data.country === "") {
      setCommonAlert({ open: true, msg: t("country_blank_error") });
    } else if (data.approved === "") {
      setCommonAlert({ open: true, msg: t("check_approve_status") });
    } else if ((data.companyName &&  data.companyName.length === 0) || !data.companyAddress) {
      setCommonAlert({ open: true, msg: t("proper_company_details") });
    } else {
      new Promise((resolve, reject) => {
        setLoading(true);
        setTimeout(() => {
          checkUserExists(data)
            .then((res) => {
              if (res.users && res.users.length > 0) {
                setCommonAlert({ open: true, msg: t("user_exists") });
                reject(new Error("User already exists"));
              } else if (res.error) {
                setCommonAlert({ open: true, msg: t("email_or_mobile_issue") });
                reject(new Error("Email or mobile issue"));
              } else {
                data["usertype"] = "shipper";
                data["createdAt"] = new Date().getTime();
                const c = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
                const reference = [...Array(5)].map((_) => c[~~(Math.random() * c.length)]).join("");
                data["referralId"] = reference;
                dispatch(addUser(data));
                dispatch(fetchUsersOnce());
                navigate("/users");
                resolve();
              }
          })
        }, 600);
      }).catch((e) => {
        // console.error("caught an error:",e)
      }).finally(() => {
        setLoading(false);
      });
    }
    }
  };
  return loading ? (
    <CircularLoading />
  ) : (
    <div>
      <Card
        style={{
          borderRadius: "19px",
          backgroundColor: "#fff",
          minHeight: 400,
          maxWidth: "75vw",
          marginTop: 20,
          marginBottom: 20,
          padding: 20,
          alignItems: "center",
          justifyContent: "center",
          boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
        }}
      >
        <Typography
          variant="h5"
          style={{
            margin: "10px 10px 0 5px",
            textAlign: isRTL === "rtl" ? "right" : "left",
          }}
        >
          { id ? t("update_shipper_title") : t("add_shipper_title")}
        </Typography>
        <div
         dir={isRTL === "rtl" ? "rtl" : "ltr"}
        >
          <Button
            variant="text"
            onClick={() => {
              navigate("/users");
            }}
          >
            <Typography
              style={{
                margin: "10px 10px 0 5px",
                textAlign: isRTL === "rtl" ? "right" : "left",
                fontWeight: "bold",
                color: MAIN_COLOR,
              }}
            >
              {`<<- ${t("go_back")}`}
            </Typography>
          </Button>
        </div>
        <Grid
          container
          spacing={5}
          sx={{ direction:isRTL === "rtl" ? "rtl" : "ltr",}}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <div style={{ width: 200, height: 250 }}>
              {profileImage ? (
                <div
                  onClick={() => fileInputRef.current.click()}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={URL.createObjectURL(profileImage)}
                    alt="Profile"
                    style={{
                      width: 200,
                      height: 250,
                      borderRadius: "19px",
                    }}
                  />
                </div>
              ) : (
                <div
                  onClick={() => fileInputRef.current.click()}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <Avatar
                    sx={{
                      width: 200,
                      height: 250,
                      display: "flex",
                      flexDirection: "column",
                      boxShadow: 3,
                      border: "2px dashed #B5B5B0",
                      fontSize: 16,
                      background: "none",
                      color: "inherit",
                      fontWeight: "bold",
                    }}
                    variant="square"
                  >
                    <FileUploadIcon
                      sx={{
                        fontSize: 100,
                        marginBottom: 3,
                        color: "grey",
                      }}
                    />
                    {t("upload_profile_image")}
                  </Avatar>
                </div>
              )}
              <input
                onChange={(event) => profileImageChange(event)}
                multiple={false}
                ref={fileInputRef}
                type="file"
                hidden
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                gridTemplateColumns: "50%",
                rowGap: "20px",
                marginY: 1,
                direction:isRTL === "rtl" ? "rtl" : "ltr",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <TextField
                  label={t("firstname")}
                  id="firstName"
                  value={data?.firstName || ""}
                  fullWidth
                  onChange={handleInputChange}
                  className={
                    isRTL === "rtl" ? classes.rootRtl_1 : classes.textField
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <TextField
                  label={t("last_name")}
                  id="lastName"
                  value={data?.lastName || ""}
                  fullWidth
                  onChange={handleInputChange}
                  className={
                    isRTL === "rtl" ? classes.rootRtl_1 : classes.textField
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <TextField
                  label={t("mobile")}
                  id="mobile"
                  value={
                    settings.AllowCriticalEditsAdmin
                      ? data?.mobile || ""
                      : t("hidden_demo")
                  }
                  fullWidth
                  disabled={id ? true : false}
                  onChange={handleInputChange}
                  className={
                    isRTL === "rtl" ? classes.rootRtl_2 : classes.textField
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  label={t("email")}
                  id="email"
                  value={
                    settings.AllowCriticalEditsAdmin
                      ? data?.email || ""
                      : t("hidden_demo")
                  }
                  fullWidth
                  disabled={id ? true : false}
                  onChange={handleInputChange}
                  className={
                    isRTL === "rtl" ? classes.rootRtl : classes.textField
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  label={t("CompanyName")}
                  id="companyName"
                  value={
                    settings.AllowCriticalEditsAdmin
                      ? data?.companyName || ""
                      : t("hidden_demo")
                  }
                  fullWidth
                  onChange={handleInputChange}
                  className={
                    isRTL === "rtl" ? classes.rootRtl : classes.textField
                  }
                />
              </Grid>


              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <GoogleMapsAutoComplete
                variant={"outlined"}
                placeholder={t('company_address')}
                value={data && data.companyAddress ? data.companyAddress.dec : ""}
                className={classes.items}
                onChange={
                  (value) => {
                    setData({ ...data, companyAddress: { dec: value.description, lat: value.coords.lat, lng: value.coords.lng} })
                  }
                }
                style={{width: '100%', marginTop: '15px', marginBottom: '5px'}}
              />
              </Grid>
              {auth && auth.profile.usertype === 'admin' && auth.profile.country_code === undefined ?
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                { countries && countries.length > 0 ?
                  <FormControl fullWidth style={{ direction: isRTL === "rtl" ? "rtl" : 'ltr' }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      className={isRTL === "rtl" ? classes.right : ""}
                      sx={{ "&.Mui-focused": { color: colors.MAIN_COLOR } }}
                    >
                      {t("select_country")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={data.country || ""}
                      label={t("select_country")}
                      onChange={handleCountryChange}
                      className={isRTL === "rtl" ? classes.selectField_rtl : classes.selectField}
                    >
                    {countries.map((item, idx)=>(
                      <MenuItem style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }} value={item.label} key = {idx}>{item.label}</MenuItem>
                    ))}
                    </Select>
                  </FormControl>
                : null}
              </Grid>
              : null}

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControl
                  fullWidth
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    className={isRTL === "rtl" ? classes.right : ""}
                    sx={{ "&.Mui-focused": { color: MAIN_COLOR } }}
                  >
                    {t("approve_status")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data?.approved || false}
                    label="Approved Status"
                    onChange={handleChangeApproved}
                    className={
                      isRTL === "rtl"
                        ? classes.selectField_rtl
                        : classes.selectField
                    }
                  >
                    <MenuItem
                      style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                      value={true}
                    >
                      {t("approved")}
                    </MenuItem>
                    <MenuItem
                      style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                      value={false}
                    >
                      {t("not_approved")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Button
                  style={{
                    borderRadius: "5px",
                    backgroundColor: MAIN_COLOR,
                    minHeight: 65,
                    minWidth: "100%",
                    textAlign: "center",
                    boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
                  }}
                  onClick={handleUpdate}
                  variant="contained"
                >
                  <Typography
                    style={{
                      color: colors.WHITE,
                      textAlign: "center",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    {t("update")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
};

export default UpdateShipper;
