import {
    FETCH_DELIVERY_LOCATION,
    FETCH_DELIVERY_LOCATION_SUCCESS,
    FETCH_DELIVERY_LOCATION_FAILED,
    EDIT_DELIVERY_LOCATION
} from "../store/types"

export const INITIAL_STATE = {
    deliverylocation:null,
    loading: false,
    error:{
        flag:false,
        msg:null
    }
}

export const deliverylocationreducer = (state = INITIAL_STATE,action)=>{
    switch(action.type){
        case FETCH_DELIVERY_LOCATION:
            return{
                ...state,
                loading:true
            };

        case FETCH_DELIVERY_LOCATION_SUCCESS:
            return{
                ...state,
                deliverylocation:action.payload,
                loading:false
            };

        case FETCH_DELIVERY_LOCATION_FAILED:
            return{
                ...state,
                deliverylocation:null,
                loading:false,
                error:{
                flag:true,
                msg:action.payload
                }
            };

        case EDIT_DELIVERY_LOCATION:
            return state;

        default:
            return state;
    }
}