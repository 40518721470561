export const colors = {
  LandingPage_Background: '#FFFFFF',
  LandingPage_Description: '#373737',
  Button_Primary: '#46F34D',
  Button_Secondary: '#D1A219', 
  Header_Background: "#FFFFFF",
  Header_Content: '#000000',
  Black: '#000000',
  Header: '#383C3D',
  Header_Text: "#FFFFFF",
  Header_Text_back: "#435152",
  Table_Header: '#BFCACD',
  Action_Button_Back: '#0A6AAD',
  ALERT_BUTTON_GOTO_COLOR: "#c6e2f6",
  ALERT_BUTTON_CLOSE_COLOR:  "#ea969d",
  HEADER: '#2a383b',
  BORDER_BACKGROUND: "#d6d6d6",
  CAR_BOX_EVEN:'#DEDFD5',
  CAR_BOX_ODD:'#f5bf19',
  CARD_LABEL:"#4C5152",
  CARD_DETAIL:"#F5F5F5",
  STATUS_COMPLETE:"#2CDE3A",
  STATUS_CANCELED:"#DF2E38",
  STATUS_PENDING:"##FFCD4B",

  TAXIPRIMARY: "#474747",
  TAXISECONDORY: "#DDDEEE",
  DELIVERYPRIMARY: "#8559a5",
  DELIVERYSECONDORY: "#eaceff",
  BIDTAXIPRIMARY: "#00564c",
  BIDTAXISECONDORY: "#eee7ca",
  TRANSPARENT: "transparent",
  WHITE: "#ffffff",
  BLACK: "#000000",
  RED: "#FF1E00",
  GREEN: "#0CAB03",
  YELLOW: "#FFDE59 ",
  FOOTERTOP: "#cccccc",
}